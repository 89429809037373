import React from 'react';
import Icons from "../../utils/icons"

const Breadcrumb = (props) => {
    const { parent_menu, parent_menu_url, second_level_page, second_level_page_url, current_page, current_page_url } = props;

    return (
        <div className="breadcrumb">
            <a href="admin/dashboard" className="breadcrumb_link">Dashboard</a>
            <span className="breadcrumb_separator"><img src={Icons.caret_right}></img></span>
            { parent_menu_url == "#" ? 
                <a href="" className="breadcrumb_link">{parent_menu}</a>
                :
                <a href={parent_menu_url} className="breadcrumb_link">{parent_menu}</a>
            }
            <span className="breadcrumb_separator"><img src={Icons.caret_right}></img></span>
            {second_level_page && (
                <>
                    <a href={second_level_page_url} className="breadcrumb_link">{second_level_page}</a>
                    <span className="breadcrumb_separator"><img src={Icons.caret_right}></img></span>
                </>
            )}
            <a className="breadcrumb_link--active" style={{cursor:'auto'}}>{current_page}</a>
        </div>
    );
};

export default Breadcrumb;
