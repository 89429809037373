import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../config/apiConfig';
import { apiBaseURL } from '../constants';
import _ from 'lodash';

export const fetchAllVendors = createAsyncThunk(
    'vendors/fetchAllVendors',
    async (filter = {}) => {
        let url = apiBaseURL.ALL_VENDORS;
        if (!_.isEmpty(filter)) {
            const params = new URLSearchParams();
            if (filter.page) params.append('page', filter.page);
            if (filter.size) params.append('size', filter.size);
            if (filter.searchTerm) params.append('searchTerm', filter.searchTerm);
            if (filter.status) params.append('status', filter.status);
            if (filter.businessType) params.append('businessType', filter.businessType);
            if (filter.toDate) params.append('toDate', filter.toDate);
            if (filter.fromDate) params.append('fromDate', filter.fromDate);

            url += `?${params.toString()}`;
        }
        const response = await apiConfig.get(url);
        return response.data;
    }
);

export const fetchVendorDetails = createAsyncThunk(
    'vendors/fetchVendorDetails',
    async (vendorId) => {
        const response = await apiConfig.get(apiBaseURL.VENDOR_DETAILS_BY_ID + '/' + vendorId);

        return response;
    }
);

export const updateVendorDetails = createAsyncThunk(
    'vendors/updateVendorDetails',
    async (vendorDetails, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiConfig.put(apiBaseURL.UPDATE_VENDOR_DETAILS_BY_ID, vendorDetails);
            dispatch(
                addToast({
                    text: getFormattedMessage("vendors.success.status.message"),
                })
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to update vendor details");
        }
    }
);

export const addVendorDetails = createAsyncThunk(
    "vendors/addVendorDetails",
    async (data, { rejectWithValue }) => {
        try {
            const response = await apiConfig.post(apiBaseURL.ADD_VENDOR_DETAILS, data);
            return response.data.response;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to add vendor details");
        }
    }
);

export const deleteVendorDetails = createAsyncThunk(
    'vendors/deleteVendorDetails',
    async (vendorId, { dispatch, rejectWithValue }) => {
        try {
            const response = await apiConfig.put(apiBaseURL.DELETE_VENDOR_DETAILS_BY_ID + '/' + vendorId);
            dispatch(
                addToast({
                    text: getFormattedMessage("vendors.success.delete.message"),
                })
            );
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.errors?.[0] || "An error occurred. Please try again.";
            dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
            return rejectWithValue(error.errorMessage);
        }
    }
);

export const fetchVendorId = createAsyncThunk(
    'vendors/fetchVendorId',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GENERATE_VENDOR_ID);

        return response.data;
    }
);

export const addVendorImage = createAsyncThunk(
    "vendors/addVendorImage",
    async (type, vendorId, data, { rejectWithValue }) => {
        try {
            const response = await apiConfig.post(apiBaseURL.UPLOAD_VENDOR_IMAGE + '/' + type + '/vendorId', data);
            return response.data.response;
        } catch (error) {
            return rejectWithValue(error.response?.data || "Failed to upload image");
        }
    }
);

export const downloadNicImage = createAsyncThunk(
    'vendors/downloadNicImage',
    async (id) => {
        const response = await apiConfig.get(apiBaseURL.DOWNLOAD_NIC_IMAGE + '/' + id + '?type=profile');

        return response.data;
    }
);

export const viewImage = createAsyncThunk(
    'vendors/viewImage',
    async (id) => {
        const response = await apiConfig.get(apiBaseURL.VIEW_IMAGES + '/' + id + '?type=feNic');

        return response.data;
    }
);

export const downloadExcelReport = createAsyncThunk(
    'vendors/downloadExcelReport',
    async () => {
        const response = await apiConfig.get(apiBaseURL.DOWNLOAD_VENDOR_EXCEL_REPORT);

        return response.data;
    }
);

const vendorSlice = createSlice({
    name: 'vendors',
    initialState: {
        isVendorsLoading: false,
        allVendors: [],
        totalRecord: 0,
        totalPages: 0,
        error: null,
        vendorDetails: null,
        isVendorDetailsLoading: false,
        updateVendorDetailsLoading: false,
        updateVendorDetailsSuccess: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllVendors.pending, (state) => {
                state.isVendorsLoading = true;
                state.error = null;
            })
            .addCase(fetchAllVendors.fulfilled, (state, action) => {
                state.isVendorsLoading = false;
                state.allVendors = action.payload.data;
                state.totalRecord = action.payload.totalItems;
                state.totalPages = action.payload.totalPages
            })
            .addCase(fetchAllVendors.rejected, (state, action) => {
                state.isVendorsLoading = false;
                state.error = action.error.message;
                console.error('Fetch vendors failed:', action.error);
            })

            .addCase(fetchVendorDetails.pending, (state) => {
                state.isVendorDetailsLoading = true;
                state.error = null;
            })
            .addCase(fetchVendorDetails.fulfilled, (state, action) => {
                state.isVendorDetailsLoading = false;
                state.vendorDetails = action.payload.data;
            })
            .addCase(fetchVendorDetails.rejected, (state, action) => {
                state.isVendorDetailsLoading = false;
                state.error = action.error.message;
            })

            /*.addCase(updateVendorDetails.pending, (state) => {
                state.updateVendorDetailsLoading = true;
                state.error = null;
                state.updateVendorDetailsSuccess = null;
            })
            .addCase(updateVendorDetails.fulfilled, (state, action) => {
                state.updateVendorDetailsLoading = false;
                state.updateVendorDetailsSuccess = action.payload.message;
            })
            .addCase(updateVendorDetails.rejected, (state, action) => {
                state.updateVendorDetailsLoading = false;
                state.error = action.payload || "Failed to update vendor details";
            })*/

            .addCase(deleteVendorDetails.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteVendorDetails.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(deleteVendorDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

export default vendorSlice.reducer;
