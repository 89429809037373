import React from "react";
import DeleteModel from "../../../shared/action-buttons/DeleteModel";
import { getFormattedMessage } from "../../../shared/sharedMethod";

const DeleteService = (props) => {
    const { DeleteServiceInfo, onDelete, deleteModel, onClickDeleteModel } = props;

    const deleteUserClick = () => {
        DeleteServiceInfo(onDelete.id);
        onClickDeleteModel(false);
    };

    return (
        <div>
            {deleteModel && (
                <DeleteModel
                    onClickDeleteModel={onClickDeleteModel}
                    deleteModel={deleteModel}
                    deleteUserClick={deleteUserClick}
                    title={getFormattedMessage("vendor.title")}
                    name={getFormattedMessage("vendor.title")}
                />
            )}
        </div>
    );
};

export default DeleteService;
