import React, { useState, useEffect } from 'react';
import AvailableJobs from '../jobs/available-jobs/AvailableJobs';
import Myjobs from '../jobs/my-jobs/MyJobs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllJobs, fetchMyJobs, updateJobStatus, claimJob } from '../../slices/jobSlice';
import Spinner from "../../shared/components/loaders/Spinner";

const Jobs = (props) => {
  const { allJobs, myJobs, allJobsLoading, error } = useSelector((state) => state.jobs);
  const {formattedMessage} = props;
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const handleClaimJob = (jobId) => {
    dispatch(claimJob(jobId))
      .unwrap()
      .then(() => {
        dispatch(fetchAllJobs());
      })
      .catch(error => {
        console.error('Error claiming job:', error);
      });
  };

  useEffect(() => {
    dispatch(fetchAllJobs());
    dispatch(fetchMyJobs());
  }, [dispatch]);
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
      <div className="job-container">
        <div className="job-tab-header">
          <div className={`tab-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>
            <h4>{formattedMessage("dashboard.available-jobs")}</h4>
            <h4 className="tab-count">{allJobs.length.toString().padStart(2, '0')}</h4>
          </div>
          <div className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>
            <h4>{formattedMessage("dashboard.my-jobs")}</h4>
            <h4 className="tab-count">{myJobs.length.toString().padStart(2, '0')}</h4>
          </div>
        </div>
        {allJobsLoading ? <Spinner /> : <>
        <div className="content-separator" />
        { activeTab === 0 && (
          <AvailableJobs jobs={allJobs} handleClaimJob={handleClaimJob} />
        )}
        {activeTab === 1 && (
          <Myjobs allMyJobs={myJobs} formattedMessage={formattedMessage} updateJobStatus={updateJobStatus} />
        )}
        </>
        }
      </div>
  );
};

export default Jobs;
