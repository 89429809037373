import briefcase from "../assets/images/briefcase.svg";
import wrench from "../assets/images/wrench.svg";
import login_icon from '../assets/images/login-icon.svg';
import eye_closed from '../assets/images/eye-closed.svg';
import error_email from '../assets/images/error-email.svg';
import error_password from '../assets/images/error-password.svg';
import reset_password_icon from '../assets/images/reset-password-icon.svg';
import briefcase_icon from "../assets/images/briefcase-icon.svg";
import list_checks_icon from "../assets/images/list-checks-icon.svg";
import check_icon from "../assets/images/check-icon.svg";
import white_blue_bg_frame from "../assets/images/white-blue-bg-frame.svg";
import light_blue_bg_frame from "../assets/images/light-blue-bg-frame.svg";
import blue_bg_frame from "../assets/images/blue-bg-frame.svg";
import back_icon from "../assets/images/arrow-left.svg";
import car_cog from "../assets/images/car-cog.svg";
import caret_circle_down from "../assets/images/caret-circle-down.svg";
import caret_circle_up from "../assets/images/caret-circle-up.svg";
import logo_icon from "../assets/images/logo-icon.svg";
import bell from "../assets/images/bell.svg";
import avatar from "../assets/images/avatar.png";
import drawer from '../assets/images/drawer.svg';
import caret_up from "../assets/images/caret-up.svg";
import menu_unfold from '../assets/images/menu-unfold.svg';
import menu_fold from '../assets/images/menu-fold.svg';
import dashboard_icon from '../assets/images/dashboard-icon.svg';
import profile_icon from '../assets/images/profile-icon.svg';
import signout_icon from '../assets/images/signout-icon.svg';
import clock_counter_clockwise from "../assets/images/clock-counter-clockwise.svg";
import pencil_simple_line from "../assets/images/pencil-simple-line.svg";
import close_icon from "../assets/images/pop-up-close.svg";
import arrow_left from "../assets/images/arrow-left.svg";
import users_three from "../assets/images/users-three.svg";
import cloud_arrow_up from "../assets/images/cloud-arrow-up.svg";
import funnel_simple from "../assets/images/funnel-simple.svg";
import magnifying_glass from "../assets/images/magnifying-glass.svg";
import plus from "../assets/images/plus.svg";
import warning_octagon from "../assets/images/warning-octagon.svg";
import caret_down from "../assets/images/caret-down.svg";
import caret_up_white from "../assets/images/caret-up-white.svg";
import caret_right_white from "../assets/images/caret-right-white.svg";
import caret_right from "../assets/images/caret-right.svg";
import view from "../assets/images/view.svg";
import exls from "../assets/images/exls.svg";
import trash from "../assets/images/trash.svg";
import caret_left from "../assets/images/caret-left.svg";
import caret_next from "../assets/images/caret-next.svg";
import caret_black_down from "../assets/images/caret-down-colored.svg"
import calendar from "../assets/images/calendar.svg"
import trash_red from "../assets/images/trash-red.svg"
import short_icon from "../assets/images/short-icon.svg"
import progress_wrench from "../assets/images/progress-wrench.svg"
import delete_warning from "../assets/images/delete-warning.svg";
import download from "../assets/images/download.svg";
import nic_sample from "../assets/images/nic-sample.svg";
import pdf_file from "../assets/images/pdf-file.svg";
import pdf_icon from "../assets/images/pdf-icon.svg";
import asterisk from "../assets/images/asterisk.svg";
import percentage from "../assets/images/percentage.svg";
import warning_circle from "../assets/images/warning-circle.svg";
import excel_icon from "../assets/images/excel-icon.svg";
import image from "../assets/images/image.svg";
import spare_part from "../assets/images/spare-part.svg";
import  squar_out from "../assets/images/squar-out.svg";
import dubble_check from "../assets/images/dubble-check.svg";

const Icons = {
  briefcase,
  wrench,
  login_icon,
  eye_closed,
  error_email,
  error_password,
  reset_password_icon,
  briefcase_icon,
  list_checks_icon,
  check_icon,
  white_blue_bg_frame,
  light_blue_bg_frame,
  blue_bg_frame,
  back_icon,
  car_cog,
  caret_circle_down,
  caret_circle_up,
  logo_icon,
  bell,
  avatar,
  drawer,
  caret_up,
  menu_unfold,
  menu_fold,
  dashboard_icon,
  profile_icon,
  signout_icon,
  clock_counter_clockwise,
  pencil_simple_line,
  close_icon,
  arrow_left,
  users_three,
  cloud_arrow_up,
  funnel_simple,
  magnifying_glass,
  plus,
  warning_octagon,
  caret_down,
  caret_right,
  view,
  exls,
  trash,
  trash_red,
  caret_left,
  caret_next,
  caret_black_down,
  calendar,
  short_icon,
  progress_wrench,
  delete_warning,
  download,
  nic_sample,
  pdf_file,
  pdf_icon,
  asterisk,
  percentage,
  warning_circle,
  excel_icon,
  image,
  caret_up_white,
  caret_right_white,
  spare_part,
  squar_out,
  dubble_check
}

export default Icons;

