import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Icons from "../../../utils/icons";
import MasterLayout from '../../MasterLayout';
import TabTitle from '../../../shared/tab-title/TabTitle';
import Breadcrumb from '../../../shared/breadcrumb/Breadcrumb';
import { getFormattedMessage, placeholderText } from '../../../shared/sharedMethod';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVendorDetails } from '../../../slices/vendorSlice';
import { useParams } from "react-router-dom";
import Spinner from "../../../shared/components/loaders/Spinner";

const VendorDetails = () => {
  const { vendorDetails, isVendorDetailsLoading, error } = useSelector((state) => state.vendors);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVendorDetails(id));
  }, [dispatch]);

  return (
    <MasterLayout>
      <TabTitle title={placeholderText("vendors.create.title")} />
      <Breadcrumb
        parent_menu="Vendor Management"
        parent_menu_url="#/admin/vendors"
        second_level_page="Vendors" 
        second_level_page_url="#/admin/vendors" 
        current_page="View Vendors"
        current_page_url=""
      />
      {isVendorDetailsLoading ? <Spinner /> : <>
        <div className="view-vendor-container">
          <div className="view-vendor-header">
            <div className="back">
              <Link to="/admin/vendors">
                <img src={Icons.arrow_left} alt="Back Icon" />
              </Link>
              <h3>View Vendor</h3>
            </div>
            <button className="button button-edit-vendor">
              <img src={Icons.pencil_simple_line} alt="Edit Icon" />
              <span>Edit Vendor</span>
            </button>
          </div>
          <div className="view-vendor-content">
            <div className="personal-info">
              <h4 className="info-header">
                Personal Information
              </h4>
              <div className="entity">
                <div className="entity-image-container">
                  <img src={Icons.avatar} alt="Avatar Icon" className='entity-image entity-image__avatar' />
                </div>
                <h4 className="entity-value">
                  {vendorDetails && vendorDetails.fullName	 ? vendorDetails.fullName	 : '-'}
                </h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Mobile Number</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.mobileNumber ? vendorDetails.mobileNumber : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Email Address</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.emailAddress ? vendorDetails.emailAddress : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">NIC</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.nic ? vendorDetails.nic : '-'}</h4>
              </div>
              <div className="entity entity__colunm">
                <h5 className="entity-label">Uploaded NIC</h5>
                <div className='entity-container'>
                  <div className='nic-container'>
                    <div className="nic-header">
                      <h5>NIC Front</h5>
                      <div className="nic-download">
                        <img src={Icons.download} alt="Download Icon" />
                      </div>
                    </div>
                    <div className="nic-image">
                      <figure>
                        <img src={Icons.nic_sample} alt="NIC Front" />
                      </figure>
                    </div>
                    <div className="nic-info">
                      <h4>nic_front.jpg</h4>
                      <span>2MB</span>
                    </div>
                  </div>
                  <div className='nic-container'>
                    <div className="nic-header">
                      <h5>NIC Back</h5>
                      <div className="nic-download">
                        <img src={Icons.download} alt="Download Icon" />
                      </div>
                    </div>
                    <div className="nic-image">
                      <figure>
                        <img src={Icons.nic_sample} alt="NIC Front" />
                      </figure>
                    </div>
                    <div className="nic-info">
                      <h4>nic_back.jpg</h4>
                      <span>2MB</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="business-info">
              <h4 className="info-header">
                Business Information
              </h4>
              <div className="entity">
                <div className="entity-image-container">
                  <img src={Icons.logo_icon} className='entity-image' />
                </div>	
                <h4 className="entity-value">{vendorDetails && vendorDetails.businessName ? vendorDetails.businessName : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Business Address Line 1</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.businessAddressLine1 ? vendorDetails.businessAddressLine1 : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Business Address Line 2</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.businessAddressLine2 ? vendorDetails.businessAddressLine2 : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">City/Town</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.cityOrTown ? vendorDetails.cityOrTown : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Postal Code</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.postalCode ? vendorDetails.postalCode : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">District</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.district ? vendorDetails.district : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Province</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.province ? vendorDetails.province : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Country</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.country ? vendorDetails.country : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Telephone No</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.businessTelephone ? vendorDetails.businessTelephone : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Business Email Address</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.businessEmail ? vendorDetails.businessEmail : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Business Type</h5>
                <h4 className="entity-value">
                {vendorDetails && vendorDetails.businessTypeSaveRequestDtoList && vendorDetails.businessTypeSaveRequestDtoList.length > 0
                  ? vendorDetails.businessTypeSaveRequestDtoList.map((type, index) => (
                      <span key={type.businessPrimaryRateTypeId}>
                        {type.businessRateTypeName}
                        {index < vendorDetails.businessTypeSaveRequestDtoList.length - 1 && " | "}
                      </span>
                    ))
                  : '-'}
                </h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Website URL</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.website ? vendorDetails.website : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Registration No</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.registrationNumber ? vendorDetails.registrationNumber : '-'}</h4>
              </div>
              <div className="entity entity__colunm">
                <h5 className="entity-label">Registration Document</h5>
                <div className='entity-container'>
                  <div className='reg-doc-container'>
                    <div className='reg-doc__left'>
                      <img src={Icons.pdf_icon} alt="PDF Icon" />
                      <div className="reg-doc-info">
                        <h4>registration_document.pdf</h4>
                        <span>2MB</span>
                      </div>
                    </div>
                    <div className='reg-doc__right'>
                      <img src={Icons.download} alt="Download Icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="additional-info">
              <h4 className="info-header">
                Additional Information
              </h4>
              <div className="entity">
                <h5 className="entity-label">Vendor ID</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.vendorId ? vendorDetails.vendorId : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Joined Date</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.joinDate ? vendorDetails.joinDate : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Registered By</h5>
                <h4 className="entity-value">{vendorDetails && vendorDetails.registeredBy ? vendorDetails.registeredBy : '-'}</h4>
              </div>
              <div className="entity">
                <h5 className="entity-label">Status</h5>
                {vendorDetails ?
                  <div className={`entity-status ${vendorDetails.status ? 'entity-status__active' : 'entity-status__inactive'}`}>
                    <h6>{vendorDetails.status ? "Active" : "Inactive"}</h6>
                  </div>
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </MasterLayout>
  )
}

export default VendorDetails;