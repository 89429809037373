import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../config/apiConfig';
import { apiBaseURL } from '../constants';
import _ from 'lodash';

export const fetchAllBusinessTypes = createAsyncThunk(
    'taxonomy/fetchAllBusinessTypes',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_BUSINESS_TYPES_LIST);

        return response.data;
    }
);

export const fetchAllServicesTypes = createAsyncThunk(
    'taxonomy/fetchAllServicesTypes',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_SERVICE_TYPES);

        return response.data;
    }
);

export const fetchAllServicesCategory = createAsyncThunk(
    'taxonomy/fetchAllServicesCategory',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_SERVICE_CATEGORY);

        return response.data;
    }
);

export const fetchAllTechnicanTypes = createAsyncThunk(
    'taxonomy/fetchAllTechnicanTypes',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_TECHNICAN_TYPES);

        return response.data;
    }
);

export const fetchAllServiceRateTypes = createAsyncThunk(
    'taxonomy/fetchAllServiceRateTypes',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_SERVICE_RATE_TYPES);

        return response.data;
    }
);

const taxonomySlice = createSlice({
    name: 'locations',
    initialState: {
        allBusinessTypes: [],
        allServicesTypes: [],
        allServicesCategory: [],
        allTechnicanTypes: [],
        allServiceRateTypes: [],
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBusinessTypes.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchAllBusinessTypes.fulfilled, (state, action) => {
                state.allBusinessTypes = action.payload.data;
            })
            .addCase(fetchAllBusinessTypes.rejected, (state, action) => {
                state.error = action.error.message;
                console.error('Fetch business types failed:', action.error);
            })

            .addCase(fetchAllServicesTypes.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchAllServicesTypes.fulfilled, (state, action) => {
                state.allServicesTypes = action.payload.data;
            })
            .addCase(fetchAllServicesTypes.rejected, (state, action) => {
                state.error = action.error.message;
                console.error('Fetch service types failed:', action.error);
            })

            .addCase(fetchAllServicesCategory.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchAllServicesCategory.fulfilled, (state, action) => {
                state.allServicesCategory = action.payload.data;
            })
            .addCase(fetchAllServicesCategory.rejected, (state, action) => {
                state.error = action.error.message;
                console.error('Fetch service category failed:', action.error);
            })

            .addCase(fetchAllTechnicanTypes.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchAllTechnicanTypes.fulfilled, (state, action) => {
                state.allTechnicanTypes = action.payload.data;
            })
            .addCase(fetchAllTechnicanTypes.rejected, (state, action) => {
                state.error = action.error.message;
                console.error('Fetch technician types failed:', action.error);
            })

            .addCase(fetchAllServiceRateTypes.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchAllServiceRateTypes.fulfilled, (state, action) => {
                state.allServiceRateTypes = action.payload.data;
            })
            .addCase(fetchAllServiceRateTypes.rejected, (state, action) => {
                state.error = action.error.message;
                console.error('Fetch service rate types failed:', action.error);
            })
    },
});

export default taxonomySlice.reducer;
