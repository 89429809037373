import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Button } from 'antd';
import Icons from '../../utils/icons';
import AddModalSelect from './AddModalSelect';

const CustomSubGroupSelect = ({}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [expandedSubGroups, setExpandedSubGroups] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const options = [
    {
      label: 'Mechanical',
      options: [
        {
          label: 'Electrical/Electronic',
          subOptions: [
            { label: 'Electrician', value: 'electrician' },
            { label: 'Electrical Engineer', value: 'electrical-engineer' },
            { label: 'Electronics Engineer', value: 'electronics-engineer' },
            { label: 'Electrical Technician', value: 'electrical-technician' },
          ]
        },
        {
          label: 'Mechanic',
          subOptions: [
            { label: 'Automotive Mechanic', value: 'automotive-mechanic' },
            { label: 'Diesel Mechanic', value: 'diesel-mechanic' },
            { label: 'Motorcycle Mechanic', value: 'motorcycle-mechanic' },
          ]
        },
      ],
    },
    {
      label: 'IT',
      options: [
        {
          label: 'Software Developer',
          subOptions: [
            { label: 'Android Developer', value: 'android-developer' },
            { label: 'iOS Developer', value: 'ios-developer' },
            { label: 'Java Developer', value: 'java-developer' },
            { label: 'Python Developer', value: 'python-developer' },
            { label: 'React Developer', value: 'react-developer' },
          ]
        },
        {
          label: 'Network Engineer',
          subOptions: [
            { label: 'Network Administrator', value: 'network-administrator' },
            { label: 'Network Analyst', value: 'network-analyst' },
            { label: 'Network Architect', value: 'network-architect' },
            { label: 'Network Engineer', value: 'network-engineer' },
          ]
        },
      ],
    },
  ];

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleGroup = (label) => {
    setExpandedGroups((prev) =>
      Array.isArray(prev)
        ? prev.includes(label)
          ? prev.filter((group) => group !== label)
          : [...prev, label]
        : []
    );
  };

  const toggleSubGroup = (label) => {
    setExpandedSubGroups((prev) =>
      Array.isArray(prev)
        ? prev.includes(label)
          ? prev.filter((group) => group !== label)
          : [...prev, label]
        : []
    );
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    if (!selectedOptions || selectedOptions.length === 0) {
      setExpandedGroups([]);
      return;
    }

    const group = options.find((group) =>
      group.options?.some((item) => item.value === selectedOptions[selectedOptions.length - 1].value)
    );
    if (group?.label && !expandedGroups.includes(group.label)) {
      setExpandedGroups((prev) => [...prev, group.label]);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter((opt) => opt.value !== optionToRemove.value);
    setSelectedOptions(updatedOptions);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const GroupedOptions = {
    Group: (props) => {
      const { data, children } = props;
      const isExpanded = expandedGroups.includes(data.label);

      return (
        <div className={`group ${isExpanded ? 'expanded' : ''}`}>
          <div
            className={`group-title ${isExpanded ? 'expanded' : ''}`}
            onClick={() => toggleGroup(data.label)}
          >
            <span>{data.label}</span>
            <img
              src={Icons.caret_black_down}
              alt="chevron-down"
              className={isExpanded ? 'rotate' : ''}
            />
          </div>
          {isExpanded && (
            <div className="group-list">
              <components.Group {...props}>{children}</components.Group>
            </div>
          )}
        </div>
      );
    },
    Option: (props) => {
      const isSubExpanded = expandedSubGroups.includes(props.data.label);

      const handleCheckboxChange = (subOption) => {
        setSelectedOptions((prevSelected) => {
          const isSelected = prevSelected.some(
            (option) => option.value === subOption.value
          );

          if (isSelected) {
            return prevSelected.filter((option) => option.value !== subOption.value);
          } else {
            return [...prevSelected, subOption];
          }
        });
      };

      const renderSubOptions = (subOptions, parentOption) => (
        <div className="sub-options">
          {subOptions.map((subOption) => {
            const isSelected = selectedOptions.some(
              (selected) => selected.value === subOption.value
            );

            return (
              <div key={subOption.value} className="checkbox-option" onClick={() => handleCheckboxChange(subOption, parentOption)}>
                <input
                  type="checkbox"
                  checked={isSelected}
                />
                <label>{subOption.label}</label>
              </div>
            );
          })}
        </div>
      );

      return (
        <div className={`sub-group ${isSubExpanded ? 'expanded' : ''}`}>
          <div
            className={`sub-group-title ${isSubExpanded ? 'expanded' : ''}`}
            onClick={() => toggleSubGroup(props.data.label)}
          >
            <span>{props.data.label}</span>
            <img
              src={Icons.caret_black_down}
              alt="chevron-down"
              className={isSubExpanded ? 'rotate' : ''}
            />
          </div>
          {isSubExpanded && renderSubOptions(props.data.subOptions, props.data)}
        </div>
      );
    },
  };

  return (
    <div className="dropdown group-option-dropdown custom-group-dropdown sub-group-dropdown">
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt="chevron-down" className={isDropdownOpen ? 'rotate' : ''} />}
        iconPosition="end"
        className={`dropdown-button ${isDropdownOpen ? 'opened' : ''} ${selectedOptions.length ? 'selected' : ''}`}
      >
        <div className="selected-options">
          {selectedOptions.length === 0
            ? "Select Vehicle Make"
            : selectedOptions.slice(0, 3).map((opt) => (
              <div key={opt.value} className="tag-container">
                <span className="tag-label">{opt.label}</span>
                <img
                  src={Icons.close_icon}
                  alt="remove"
                  className="tag-remove-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(opt);
                  }}
                />
              </div>
            ))}
          {selectedOptions.length > 3 && (
            <div className="tag-container">
              <span className="tag-label">+{selectedOptions.length - 3}</span>
            </div>
          )}
        </div>
      </Button>
      {isDropdownOpen && (
        <>
          <div className="dropdown-menu">
            <div className="dropdown-header">
              <button className="button add-option-button" onClick={handleModalOpen}>
                <h5>Add Vehicle Make</h5>
              </button>
            </div>
            <div className="dropdown-search">
              <input type="text" placeholder="Search" />
            </div>
            <Select
              components={{ ...GroupedOptions }}
              options={options}
              value={selectedOptions}
              onChange={handleOptionChange}
              isMulti={true}
              menuIsOpen={true}
              isClearable={false}
              isSearchable={false}
              tabSelectsValue={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
            />
          </div>
          <div
            onClick={toggleOpen}
            className="dropdown-blanket"
          />
          {isModalOpen && (
            <AddModalSelect
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              modalTitle="Add a Vehicle Make"
              placeholderText="Enter vehicle make"
              options={selectedOptions}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomSubGroupSelect;
