import React, { useState } from "react";
import profile_avatar from "../../assets/images/profile-avatar.png";
import Icons from "../../utils/icons";

const notifications = [
  {
    id: 1,
    message: "Vendor John Doe made changes to their...",
    time: "5min ago",
    unread: true,
  },
  {
    id: 2,
    message: "Vendor John Doe made changes to their...",
    time: "1hr 5min ago",
    unread: false,
  },
  {
    id: 3,
    message: "Vendor John Doe made changes to their...",
    time: "1hr 5min ago",
    unread: true,
  },
  {
    id: 4,
    message: "Vendor John Doe made changes to their...",
    time: "Oct 18, 2024 | 10:00 am",
    unread: true,
  },
  {
    id: 5,
    message: "Vendor John Doe made changes to their...",
    time: "Oct 18, 2024 | 15:00 pm",
    unread: true,
  },
];

const Notifications = () => {
  const [isVisible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="notifications-container">
      <div className="notify-start">
        <h2 className="notify-heading">Notifications</h2>
        <img
          src={Icons.close_icon}
          className="close-button"
          onClick={handleClose}
          alt="Close"
        />
      </div>
      <header className="notifications-header">
        <nav className="notifi-filter">
          <span className="active">All</span>
          <span className="stroke">|</span>
          <span>Unread (12)</span>
        </nav>
        <div className="mark-all-read">
          <a>
            <img src={Icons.dubble_check} alt="Mark all read" />
            Mark all as read
          </a>
        </div>
      </header>

      <div className="notifications-list">
        <h3>Today</h3>
        {notifications.slice(0, 3).map((notification) => (
          <div
            key={notification.id}
            className={`notification-item ${
              notification.unread ? "unread" : ""
            }`}
          >
            <img src={profile_avatar} alt="Profile" />
            <div className="notification-content">
              <p>{notification.message}</p>
              <span className="notification-time">{notification.time}</span>
            </div>
            {notification.unread && <div className="unread-dot"></div>}
          </div>
        ))}

        <h3>Yesterday</h3>
        {notifications.slice(3).map((notification) => (
          <div
            key={notification.id}
            className={`notification-item ${
              notification.unread ? "unread" : ""
            }`}
          >
            <img src={profile_avatar} alt="Profile" />
            <div className="notification-content">
              <p>{notification.message}</p>
              <span className="notification-time">{notification.time}</span>
            </div>
            {notification.unread && <div className="unread-dot"></div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notifications;
