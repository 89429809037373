import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Button } from 'antd';
import Icons from '../../utils/icons';
import AddModalSelect from './AddModalSelect';

const CustomGroupSelect = ({ groupedOptions, dropdownPlaceholderText, modalTitle, selectFieldLabel, selectPlaceholderText, formFieldLabel, formPlaceholderText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedGroups, setExpandedGroups] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleGroup = (label) => {
    setExpandedGroups((prev) =>
      Array.isArray(prev)
        ? prev.includes(label)
          ? prev.filter((group) => group !== label)
          : [...prev, label]
        : []
    );
  };

  const handleOptionChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);

    if (!selectedOptions || selectedOptions.length === 0) {
      setExpandedGroups([]);
      return;
    }

    const group = groupedOptions.find((group) =>
      group.groupedOptions?.some((item) => item.value === selectedOptions[selectedOptions.length - 1].value)
    );
    if (group?.label && !expandedGroups.includes(group.label)) {
      setExpandedGroups((prev) => [...prev, group.label]);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter((opt) => opt.value !== optionToRemove.value);
    setSelectedOptions(updatedOptions);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const GroupedOptions = {
    Group: (props) => {
      const { data, children } = props;
      const isExpanded = expandedGroups.includes(data.label);

      return (
        <div className={`group ${isExpanded ? 'expanded' : ''}`}>
          <div
            className={`group-title ${isExpanded ? 'expanded' : ''}`}
            onClick={() => toggleGroup(data.label)}
          >
            <span>{data.label}</span>
            <img
              src={Icons.caret_black_down}
              alt="chevron-down"
              className={isExpanded ? 'rotate' : ''}
            />
          </div>
          {isExpanded && (
            <div className="group-list">
              <components.Group {...props}>{children}</components.Group>
            </div>
          )}
        </div>
      );
    },
    Option: (props) => {
      return (
        <div className="checkbox-option" onClick={() => props.selectOption(props.data)}>
          <input
            type="checkbox"
            checked={props.isSelected}
          />
          <label>{props.data.label}</label>
        </div>
      )
    },
  };

  return (
    <div className="dropdown group-option-dropdown custom-group-dropdown">
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt="chevron-down" className={isDropdownOpen ? 'rotate' : ''} />}
        iconPosition="end"
        className={`dropdown-button ${isDropdownOpen ? 'opened' : ''} ${selectedOptions.length ? 'selected' : ''}`}
      >
        <div className="selected-options">
          {selectedOptions.length === 0
            ? dropdownPlaceholderText
            : selectedOptions.slice(0, 3).map((opt) => (
              <div key={opt.value} className="tag-container">
                <span className="tag-label">{opt.label}</span>
                <img
                  src={Icons.close_icon}
                  alt="remove"
                  className="tag-remove-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(opt);
                  }}
                />
              </div>
            ))}
          {selectedOptions.length > 3 && (
            <div className="tag-container">
              <span className="tag-label">+{selectedOptions.length - 3}</span>
            </div>
          )}
        </div>
      </Button>
      {isDropdownOpen && (
        <>
          <div className="dropdown-menu">
            <div className="dropdown-header">
              <button className="button add-option-button" onClick={handleModalOpen}>
                <h5>Add Vehicle Make</h5>
              </button>
            </div>
            <div className="dropdown-search">
              <input type="text" placeholder="Search" />
            </div>
            <Select
              components={{ ...GroupedOptions }}
              options={groupedOptions}
              value={selectedOptions}
              onChange={handleOptionChange}
              isMulti={true}
              menuIsOpen={true}
              isClearable={false}
              isSearchable={false}
              tabSelectsValue={false}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
            />
          </div>
          <div
            onClick={toggleOpen}
            className="dropdown-blanket"
          />
          {isModalOpen && (
            <AddModalSelect
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              options={selectedOptions}
              modalTitle={modalTitle}
              selectFieldLabel={selectFieldLabel}
              selectPlaceholderText={selectPlaceholderText}
              formFieldLabel={formFieldLabel}
              formPlaceholderText={formPlaceholderText}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomGroupSelect;
