import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import { Tokens } from "./constants";
import { route } from "./adminRoutes";

function AdminApp() {
    const token = localStorage.getItem(Tokens.VENDOR);

    return (
        <Routes>
            {route.map((route, index) => (
                route.ele ? (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.ele}
                    />
                    // <Route
                    //     key={index}
                    //     path={route.path}
                    //     element={token ? route.ele : <Navigate replace to="/login" />}
                    // />
                ) : null
            ))}
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>

    );
}

export default AdminApp;
