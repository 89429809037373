import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector, connect } from "react-redux";
import {useNavigate, useParams} from 'react-router-dom';
import TabTitle from '../../shared/tab-title/TabTitle';
import {resetPassword} from '../../store/action/authAction';
import {getFormattedMessage, placeholderText} from '../../shared/sharedMethod';
import {Layout} from 'antd';
import reset_password_icon from '../../assets/images/reset-password-icon.svg';
import eye_closed from '../../assets/images/eye-closed.svg';
import white_logo from '../../assets/images/karstation-logo-white.svg';
import AuthHeader from '../header/AuthHeader';
import lock_key_icon from '../../assets/images/lock-key.svg';
import error_email from '../../assets/images/error-email.svg';
import error_password from '../../assets/images/error-password.svg';

const { Content } = Layout;

const ResetPassword = (props) => {
    const {resetPassword, frontSetting} = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token, email} = useParams();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorIcon, setErrorIcon] = useState("");
    const [errorTitle, setErrorTitle] = useState("");
    const [error, setError] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    const [resetValue, setResetValue] = useState({
        password: '',
        password_confirmation: '',
        email: email,
        verificationToken: token
    });

    const [errors, setErrors] = useState({
        password: '',
        password_confirmation: '',
    });

    const handleValidation = () => {
        let errorss = {};
        let isValid = false;
        if (!resetValue['password']) {
            errorss['password'] = getFormattedMessage('user.input.password.validate.label');
        } else if (!resetValue['password_confirmation']) {
            errorss['password_confirmation'] = getFormattedMessage('user.input.confirm-password.validate.label');
        } else if (resetValue['password'] !== resetValue['password_confirmation']) {
            errorss['password_confirmation'] = 'The confirm password and password must match';
        } else {
            isValid = true;
        }
        setErrors(errorss);
        return isValid;
    };

    const prepareFormData = (data) => {
        return {
            userAuthentication: {
                email: data.email,
            },
            password: data.password,
            verificationToken: data.verificationToken,
        };
    };    

    const handleChange = (e) => {
        e.persist();
        setResetValue(inputs => ({...inputs, [e.target.name]: e.target.value}));
        setErrors('');
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const Valid = handleValidation();
        if (Valid) {
            setLoading(true);
            try {
                const response = await dispatch(
                    resetPassword(prepareFormData(resetValue), navigate, setLoading)
                );
                if (response && response.error) {
                    setErrorIcon(error_email);  
                    setErrorTitle("Failed");  
                    setError(response.error);  
                    setShowPopup(true);
                } else if (response && response.success) {
                    setErrorIcon(error_email); 
                    setErrorTitle("Success");   
                    setError(response.success); 
                    setShowPopup(true);
                }
            } catch (error) {
                setErrorIcon(error_password);  
                setErrorTitle("Unexpected Error");  
                setError("An unexpected error occurred. Please try again.");
                setShowPopup(true);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="container">
            <TabTitle title='Reset Password'/>
            <AuthHeader isLoginPage={false} />
            <div className="right-side"> 
                <div>
                    <img
                        src={white_logo}
                        alt="KarStation"
                        className="logo hide-desktop"
                    />
                </div>
                <div className="form-container reset-password">
                    <img
                        src={reset_password_icon}
                        alt="KarStation"
                        className="login-icon"
                    />
                    <h1>
                        {getFormattedMessage("reset-password.title")}
                    </h1>
                    <p>
                        Set your new password
                    </p>
                    <form>
                        <div className="mb-sm-7 mb-4">
                            <div className="d-flex justify-content-between mt-n5">
                                <div className="d-flex justify-content-between w-100">
                                    <label className="form-label">
                                        {getFormattedMessage(
                                            "change-password.input.new.label"
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="password-input-container">
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    name="password"
                                    placeholder={placeholderText(
                                        "change-password.input.new.placeholder.label"
                                    )}
                                    autoComplete="off"
                                    required
                                    value={resetValue.password}
                                    onChange={(e) => handleChange(e)}
                                />
                                <img
                                    src={passwordVisible ? eye_closed : eye_closed}
                                    alt="Toggle password visibility"
                                    className="toggle-password-icon"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                />
                            </div>
                            <span className="text-danger d-block fw-400 fs-small mt-2 text-start">
                                {errors["password"]
                                    ? errors["password"]
                                    : null}
                            </span>
                        </div>
                        <div className="mb-sm-7 mb-4">
                            <div className="d-flex justify-content-between mt-n5">
                                <div className="d-flex justify-content-between w-100">
                                    <label className="form-label">
                                        {getFormattedMessage(
                                            "change-password.input.confirm.label"
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div className="password-input-container">
                                <input
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    name="password_confirmation"
                                    placeholder={placeholderText(
                                        "change-password.input.confirm.placeholder.label"
                                    )}
                                    autoComplete="off"
                                    required
                                    value={resetValue.password_confirmation}
                                    onChange={(e) => handleChange(e)}
                                />
                                <img
                                    src={confirmPasswordVisible ? eye_closed : eye_closed}
                                    alt="Toggle password visibility"
                                    className="toggle-password-icon"
                                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                />
                            </div>
                            <span className="text-danger d-block fw-400 fs-small mt-2 text-start">
                                {errors["password_confirmation"]
                                    ? errors["password_confirmation"]
                                    : null}
                            </span>
                        </div>
                        <div className="text-center">
                            <button
                                type="submit"
                                onClick={(e) => onSubmit(e)}
                            >
                            {
                                <span>{getFormattedMessage('globally.submit-btn')}</span>
                            }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    const {frontSetting} = state;
    return {frontSetting}
};

export default connect(mapStateToProps, {resetPassword})(ResetPassword);
