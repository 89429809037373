import React, { useState } from "react";
import Select from 'react-select';
import { Button } from 'antd';
import Icons from '../../utils/icons';
import AddModalInput from "./AddModalInput";

const CustomOptionsSelect = ({ options, modalTitle, placeholderText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const onSelectChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    toggleOpen();
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(true);
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  }

  return (
    <div className="dropdown custom-option-dropdown">
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt="chevron-down" className={isDropdownOpen ? 'rotate' : ''} />}
        iconPosition="end"
        className={`dropdown-button ${isDropdownOpen ? 'opened' : ''} ${selectedOption ? 'selected' : ''}`}
      >
        {selectedOption ? `${selectedOption.label}` : ' '}
      </Button>
      {isDropdownOpen && (
        <>
          <div className="dropdown-menu">
            <div className="dropdown-header">
              <button className="button add-option-button" onClick={handleModalOpen}>
                <h5>Add Unit</h5>
              </button>
            </div>
            <Select
              options={options}
              value={selectedOption}
              onChange={onSelectChange}
              menuIsOpen={true}
              isClearable={false}
              isSearchable={true}
              tabSelectsValue={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              controlShouldRenderValue={false}
              className="dropdown-select"
            />
          </div>
          <div
            onClick={toggleOpen}
            className="dropdown-blanket"
          />
          {isModalOpen && (
            <AddModalInput
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              options={options}
              modalTitle={modalTitle}
              placeholderText={placeholderText}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomOptionsSelect;
