import AdminDashboard from "./components/dashboard/AdminDashboard";
import Profile from "./components/profile/Profile";
import ProfileEdit from "./components/profile/ProfileEdit";
import CreateVendor from "./components/admin/vendor/CreateVendor";
import Vendors from "./components/admin/vendor/Vendors";
import EditVendor from "./components/admin/vendor/EditVendor";
import VendorDetails from "./components/admin/vendor/VendorDetails";
import SpareParts from "./components/admin/spare-part/SpareParts";
import CreateSparePart from "./components/admin/spare-part/CreateSparePart";
import RateCards from "./components/admin/rate-card/RateCards";
import EditRateCard from "./components/admin/rate-card/EditRateCard";
import Services from "./components/admin/service/Services";
import CreateService from "./components/admin/service/CreateService";

export const route = [
    {
        path: "/dashboard",
        ele: <AdminDashboard />,
        permission: "", 
    },
    {
        path: "/vendors",
        ele: <Vendors />,
        permission: "", 
    },
    {
        path: "vendors/create",
        ele: <CreateVendor />,
        permission: "",
    },
    {
        path: "vendors/edit/:id",
        ele: <EditVendor />,
        permission: "",
    },
    {
        path: "vendors/detail/:id",
        ele: <VendorDetails />,
        permission: "",
    },
    {
        path: "/services",
        ele: <Services />,
        permission: "", 
    },
    {
        path: "services/create",
        ele: <CreateService />,
        permission: "",
    },
    {
        path: "/spare-parts",
        ele: <SpareParts />,
        permission: "", 
    },
    {
        path: "spare-parts/create",
        ele: <CreateSparePart />,
        permission: "",
    },
    {
        path: "spare-parts/edit/:id",
        ele: <EditVendor />,
        permission: "",
    },
    {
        path: "rate-cards",
        ele: <RateCards />,
        permission: "",
    },
    {
        path: "rate-card/edit/:id",
        ele: <EditRateCard />,
        permission: "",
    },
    {
        path: "/my-profile",
        ele: <Profile />,
        permission: "", 
    },
    {
        path: "/edit-profile",
        ele: <ProfileEdit />,
        permission: "", 
    },
];
