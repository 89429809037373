import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Icons from "../../utils/icons"
import { fetchJobsCount } from '../../slices/jobSlice';
import { Tokens } from '../../constants/index'
import Spinner from "../../shared/components/loaders/Spinner";

const Widget = (props) => {
  const { formattedMessage } = props;
  const { jobsCounts, jobsCountsLoading, error } = useSelector((state) => state.jobs);
  const [greeting, setGreeting] = useState('');
  const [openJobs, setOpenJobs] = useState(0);
  const [completedJobs, setCompletedJobs] = useState(0);
  const [undertakenJobs, setUndertakenJobs] = useState(0);
  const firstName = localStorage.getItem(Tokens.FIRST_NAME);
  const dispatch = useDispatch(); 

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }

    dispatch(fetchJobsCount());    

  }, [dispatch]);

  useEffect(() => {
    const fetchStats = async () => {
        setOpenJobs(jobsCounts[2]?.count || 0); 
        setCompletedJobs(jobsCounts[1]?.count || 0); 
        setUndertakenJobs(jobsCounts[0]?.count || 0);
    };

    fetchStats();
  });

  return (
    <div className="dashboard-widget">
      <div className="card no-border">
        <div className="greeting">
          <h1 className="greeting-time">{greeting},&nbsp;</h1>
          <h1 className="greeting-name">{ firstName} 👋</h1>
        </div>
        <p className="message">
            {formattedMessage(
              "dashboard.welcome.text"
            )}
        </p>
      </div>

      {jobsCountsLoading ? <Spinner /> : <>
        <div className="card widget-card-container">
          <div className="card widget-card"
            style={{
              backgroundImage: `url(${Icons.white_blue_bg_frame})`,
            }}>
            <div className="card-content">
              <div className="left-content">
                <h3 className="widget-title">
                  {formattedMessage(
                    "dashboard.count.open-jobs"
                  )}
                </h3>
                <h1 className="widget-count">{openJobs}</h1>
              </div>
              <div className="right-content">
                <img src={Icons.briefcase_icon} alt="Icon" className="widget-icon" />
              </div>
            </div>
          </div>

          <div className="separator" />

          <div className="card widget-card"
            style={{
              backgroundImage: `url(${Icons.light_blue_bg_frame})`,
            }}>
            <div className="card-content">
              <div className="left-content">
                <h3 className="widget-title">
                  {formattedMessage(
                    "dashboard.count.taken-jobs"
                  )}
                </h3>
                <h1 className="widget-count">{undertakenJobs}</h1>
              </div>
              <div className="right-content">
                <img src={Icons.list_checks_icon} alt="Icon" className="widget-icon" />
              </div>
            </div>
          </div>

          <div className="separator" />

          <div className="card widget-card"
            style={{
              backgroundImage: `url(${Icons.blue_bg_frame})`,
            }}>
            <div className="card-content">
              <div className="left-content">
                <h3 className="widget-title">
                  {formattedMessage(
                    "dashboard.count.completed-jobs"
                  )}
                </h3>
                <h1 className="widget-count">{completedJobs}</h1>
              </div>
              <div className="right-content">
                <img src={Icons.check_icon} alt="Icon" className="widget-icon" />
              </div>
            </div>
          </div>
        </div>
      </>
      }
    </div>
  );
};

export default Widget;
