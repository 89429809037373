import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form } from "antd";
import moment from "moment";
import Icons from "../../../utils/icons";
import * as EmailValidator from "email-validator";
import {
  getFormattedMessage,
  placeholderText,
  numValidate
} from "../../../shared/sharedMethod";
import RadioSelect from "../../../shared/select/RadioSelect";
import GroupSelect from "../../../shared/select/GroupSelect";
import UploadComponent from "../../../shared/upload/UploadComponent";
import ReactDatePicker from "../../../shared/datepicker/ReactDatePicker";

const ServiceForm = (props) => {
  const { addCustomerData, id, singleCustomer } = props;
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [disabledFormItem, setDisabledFormItem] = useState(true);

  const [customerValue, setCustomerValue] = useState({
    name: singleCustomer ? singleCustomer[0].name : "",
    dob: singleCustomer
      ? singleCustomer[0].dob === null
        ? null
        : moment(singleCustomer[0].dob).toDate()
      : null,
    email: singleCustomer ? singleCustomer[0].email : "",
    phone: singleCustomer ? singleCustomer[0].phone : "",
    country: singleCustomer ? singleCustomer[0].country : "",
    city: singleCustomer ? singleCustomer[0].city : "",
    address: singleCustomer ? singleCustomer[0].address : "",
  });

  const [errors, setErrors] = useState({
    dob: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
  });

  const handleStartDateCallback = (date) => {
    setStartDate((previousState) => {
      return { ...previousState, start_date: date };
    });
  };

  const serviceCategoryOptions = [
    { label: "STD Remove and Replace Labor", value: "std-remove-replace-labor" },
    { label: "STD Remove and Reinstall Labor", value: "std-remove-reinstall-labor" },
    { label: "STD Paint Process", value: "std-paint-process" },
    { label: "Non Standard Repair", value: "non-standard-repair" },
    { label: "PDI and Other Fixed Costs", value: "pdi-other-fixed-costs" },
  ];

  const serviceRateTypeOptions = [
    { label: "Mechanical", value: "mechanical" },
    { label: "Tinkering", value: "tinkering" },
    { label: "Painting", value: "painting" },
    { label: "Detailing", value: "detailing" },
  ];

  const groupedOptions = [
    {
      label: 'Mechanical',
      options: [
        { label: 'AC Technician', value: 'ac-technician' },
        { label: 'Electrical/Electronic', value: 'electrical-electronic' },
        { label: 'Mechanic', value: 'mechanic' },
      ],
    },
    {
      label: 'Tinkering',
      value: 'tinkering',
    },
    {
      label: 'Painting',
      value: 'painting',
    },
    {
      label: 'Detailing',
      value: 'detailing',
    },
  ];

  const disabled =
    singleCustomer &&
    singleCustomer[0].dob === customerValue.dob &&
    singleCustomer[0].phone === customerValue.phone &&
    singleCustomer[0].name === customerValue.name &&
    singleCustomer[0].country === customerValue.country &&
    singleCustomer[0].city === customerValue.city &&
    singleCustomer[0].email === customerValue.email &&
    singleCustomer[0].address === customerValue.address;

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!customerValue["name"]) {
      errorss["name"] = getFormattedMessage(
        "globally.input.name.validate.label"
      );
    } else if (!EmailValidator.validate(customerValue["email"])) {
      if (!customerValue["email"]) {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.validate.label"
        );
      } else {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.valid.validate.label"
        );
      }
    } else if (!customerValue["country"]) {
      errorss["country"] = getFormattedMessage(
        "globally.input.country.validate.label"
      );
    } else if (!customerValue["city"]) {
      errorss["city"] = getFormattedMessage(
        "globally.input.city.validate.label"
      );
    } else if (!customerValue["address"]) {
      errorss["address"] = getFormattedMessage(
        "globally.input.address.validate.label"
      );
    } else if (!customerValue["phone"]) {
      errorss["phone"] = getFormattedMessage(
        "globally.input.phone-number.validate.label"
      );
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handleCallback = (date) => {
    setCustomerValue((previousState) => {
      return { ...previousState, dob: date };
    });
    setErrors("");
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    setCustomerValue((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setErrors("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const valid = handleValidation();
    if (singleCustomer && valid) {
      if (!disabled) {
        setCustomerValue(customerValue);
      }
    } else {
      if (valid) {
        setCustomerValue(customerValue);
        addCustomerData(customerValue);
      }
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="form-header">
          <div className="back">
            <Link to="/admin/services">
              <img src={Icons.arrow_left} alt="Back Icon" />
            </Link>
            <h3>Add New Services</h3>
          </div>
          <button className="button form-button">
            <span>Upload Bulk</span>
          </button>
        </div>
        <Form className="form" layout="vertical">
          <div className="service-details">
            {/* {error && (
              <div className="main-error">
                <h4>Vendor Account Creation Failed. Please check whether all required fields are filled in correctly</h4>
                <img src={Icons.close_icon} alt="Close Icon" />
              </div>
            )} */}
            <h3 className="form-header">
              Services  Details
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="service-id" id="service-id" required />
                <label className="form-label" htmlFor="service-id">
                  <h4>Service ID</h4>
                </label>
                {/* {error && (
                  <div className="field-error">
                    <img src={Icons.warning_octagon} alt="Warning Icon" />
                    <span>Please enter First Name</span>
                  </div>
                )} */}
              </div>
              <div className="form-item">
                <input type="text" name="service-name" id="service-name"
                  required />
                <label className="form-label" htmlFor="service-name">
                  <h4>Service Name</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="description" id="description" required />
                <label className="form-label" htmlFor="description">
                  <h4>Description</h4>
                </label>
              </div>
              <div className="form-item">
                <RadioSelect
                  options={serviceCategoryOptions}
                  placeholderText="Select service category"
                />
                <label className="form-label" htmlFor="service-category">
                  <h4>Service Category</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <UploadComponent
                  text="Click to upload or drag & drop a document here"
                  mobileText="Click to upload a document here"
                  subText="PDF and XSL/XSLS format, up to 5MB"
                  buttonText="Upload Document"
                  inputId="service-checklist"
                  required={false}
                />
                <label className="form-label" htmlFor="service-checklist">
                  <h4>Service Checklist</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <div className="input-wrapper input-wrapper__radio">
                  <div className="radio-wrapper">
                    <input type="radio" name="service-parts-required" id="yes" value="yes" />
                    <label className="radio-label" htmlFor="yes">
                      Yes
                    </label>
                  </div>
                  <div className="radio-wrapper">
                    <input type="radio" name="service-parts-required" id="no" value="no" />
                    <label className="radio-label" htmlFor="no">
                      No
                    </label>
                  </div>
                </div>
                <label className="form-label" htmlFor="service-parts-required">
                  <h4>Service Parts Required</h4>
                </label>
              </div>
              <div className="form-item">
                <GroupSelect
                  groupedOptions={groupedOptions}
                  placeholderText="Select service type"
                />
                <label className="form-label" htmlFor="service-type">
                  <h4>Service Type</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <GroupSelect
                  groupedOptions={groupedOptions}
                  placeholderText="Select technician type"
                />
                <label className="form-label" htmlFor="technician-type">
                  <h4>Technician Type</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="number" name="hours" id="hours" min={0} required />
                <label className="form-label" htmlFor="hours">
                  <h4>Hours</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <RadioSelect
                  options={serviceRateTypeOptions}
                  placeholderText="Select service rate"
                />
                <label className="form-label" htmlFor="service-rate-type">
                  <h4>Service Rate Type</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="additional-cost" id="additional-cost" />
                <label className="form-label" htmlFor="additional-cost">
                  <h4>Additional Cost</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="total-service-cost" id="total-service-cost" required />
                <label className="form-label" htmlFor="total-service-cost">
                  <h4>Total Service Cost</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="add-notes">
            <div className="form-header sub-header">
              <h3>Add Notes</h3>
              <button className="button form-button">
                <img src={Icons.plus} alt="Edit Icon" />
                <span>Add New Notes</span>
              </button>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <textarea name="note" id="note" />
                <label className="form-label" htmlFor="note">
                  <h4>Note</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full note-preview">
                <div className="note-container">
                  <div className="note-content">
                    <div className="note-header">
                      <h4>Checked vehicle parts and verified that additional bumper fittings are required.</h4>
                      <img src={Icons.trash} alt="Delete Icon" />
                    </div>
                    <div className="note-item">
                      <img src={Icons.image} alt="Image Icon" />
                      <h4>image.png</h4>
                    </div>
                    <div className="note-item">
                      <img src={Icons.pdf_file} alt="PDF File Icon" />
                      <h4>note.pdf</h4>
                    </div>
                    <div className="note-footer">
                      <h6>Notes by: Super Admin on 8-22-2024 10:31:34</h6>
                    </div>
                  </div>
                </div>
                <label className="form-label">
                  <h4>Note 1</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <UploadComponent
                  text="Click to Upload or Drag & Drop Your File Here"
                  mobileText="Click to Upload or Drag & Drop Your File Here"
                  subText="DOCX, PNG, JPG and JPEG and PDF format, up to 15MB"
                  buttonText="Upload File"
                  inputId="attachments"
                  required={false}
                />
                <label className="form-label" htmlFor="attachments">
                  <h4>Attachments</h4>
                </label>
              </div>
            </div>
            <div className="form-row form-footer">
              <button
                type="submit"
                className="button"
              >
                <h5>Save Note</h5>
              </button>
              <button
                type="submit"
                className="button disabled-button"
              >
                <h5>Save Note 1</h5>
              </button>
            </div>
          </div>
          <div className="additional-info">
            <h3 className="info-header">
              Additional Information
            </h3>
            <div className="form-row">
              <div className={`form-item ${disabledFormItem ? "disabled-form-item" : ""}`}>
                <input type="text" name="spare-parts-primary-identifier" id="spare-parts-primary-identifier" />
                <label className="form-label" htmlFor="spare-parts-primary-identifier">
                  <h4>Spare Parts Primary Identifier</h4>
                </label>
              </div>
              <div className={`form-item ${disabledFormItem ? "disabled-form-item" : ""}`}>
                <ReactDatePicker
                  onChangeDate={handleStartDateCallback} id="spare-parts-added-date"
                />
                <label className="form-label" for="spare-parts-added-date">
                  <h4>Spare Parts Added Date</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className={`form-item ${disabledFormItem ? "disabled-form-item" : ""}`}>
                <ReactDatePicker
                  onChangeDate={handleStartDateCallback}
                  id="last-updated-date"
                />
                <label className="form-label" htmlFor="last-updated-date">
                  <h4>Last Updated Date</h4>
                </label>
              </div>
              <div className={`form-item ${disabledFormItem ? "disabled-form-item" : ""}`}>
                <input type="text" name="spare-parts-added-by" id="spare-parts-added-by" />
                <label className="form-label" htmlFor="spare-parts-added-by">
                  <h4>Spare Parts Added By</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              className="button button-cancel"
              onClick={() => navigate(-1)}
            >
              <h5>Cancel</h5>
            </button>
            <button
              type="submit"
              className="button button-submit"
              onClick={() => setVisible(true)}
            >
              <h5>Add Service</h5>
            </button>
          </div>
        </Form >
      </div >
    </>
  );
};

export default ServiceForm;
