import React from 'react';
import { Tooltip } from 'antd';
import Icons from "../../utils/icons";

const ActionButton = (props) => {
    const { goToEditData, item, onClickDeleteModel = true, isDeleteMode = true, isEditMode = true, goToDetailScreen, isViewIcon = false } = props;
    
    return (
        <>
            { isViewIcon === false ? null :
                <Tooltip title="View" color="#000" placement="bottom">
                    <img src={Icons.view}
                        onClick={(e) => {
                            e.stopPropagation();
                            goToDetailScreen(item.id);
                        }}>
                    </img>
                </Tooltip>
            }
            { isEditMode === false ? null :
                <Tooltip title="Edit" color="#000" placement="bottom">
                    <img src={Icons.pencil_simple_line}
                        onClick={(e) => {
                            e.stopPropagation();
                            goToEditData(item);
                        }}>
                    </img>
                </Tooltip>
            }
            { isDeleteMode === false ? null :
                <Tooltip title="Delete" color="#000" placement="bottom">
                    <img src={Icons.trash_red}
                        onClick={(e) => {
                            e.stopPropagation();
                            onClickDeleteModel(item);
                        }}>
                    </img>
                </Tooltip>
            }
        </>
    )
};
export default ActionButton;
