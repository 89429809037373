import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Button } from 'antd';
import Icons from '../../utils/icons';

const GroupSelect = ({ groupedOptions, placeholderText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [expandedGroup, setExpandedGroup] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  }

  const toggleGroup = (label) => {
    setExpandedGroup((prev) => (prev === label ? null : label));
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    const group = groupedOptions.find((group) =>
      group.options?.some((item) => item.value === option.value)
    );
    setExpandedGroup(group?.label || null);
    toggleOpen();
  };

  const Group = (props) => {
    const { data, isExpanded, toggleGroup, children } = props;
    const isSelectedGroup =
      selectedOption &&
      data.options?.some((option) => option.value === selectedOption.value);

    return (
      <div className={`group ${isExpanded ? 'expanded' : ''} ${isSelectedGroup ? 'selected-group' : ''}`}>
        <div
          className={`group-title ${isExpanded ? 'expanded' : ''} ${isSelectedGroup ? 'selected-group' : ''}`}
          onClick={() => toggleGroup(data.label)}
        >
          <span>{data.label}</span>
          <img
            src={Icons.caret_black_down}
            alt="chevron-down"
            className={isExpanded ? "rotate" : ""}
          />
        </div>
        {isExpanded && (
          <div className='group-list'>
            <components.Group {...props}>{children}</components.Group>
          </div>
        )}
      </div>
    );
  };

  const customComponents = {
    Group: (props) => (
      <Group
        {...props}
        isExpanded={expandedGroup === props.data.label}
        toggleGroup={toggleGroup}
      />
    ),
  };

  return (
    <div className='dropdown group-option-dropdown'>
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt='chevron-down' className={isDropdownOpen ? 'rotate' : ''} />}
        iconPosition='end'
        className={`dropdown-button ${isDropdownOpen ? 'opened' : ''} ${selectedOption ? 'selected' : ''}`}
      >
        {selectedOption ? `${selectedOption.label}` : placeholderText || ' '}
      </Button>
      {isDropdownOpen && (
        <>
          <div className='dropdown-menu'>
            <Select
              components={{ ...customComponents }}
              options={groupedOptions}
              value={selectedOption}
              onChange={handleOptionChange}
              menuIsOpen={true}
              isSearchable={false}
              tabSelectsValue={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
            />
          </div>
          <div
            onClick={toggleOpen}
            className='dropdown-blanket'
          />
        </>
      )}
    </div>
  );
};

export default GroupSelect;
