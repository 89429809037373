import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dropdown, Button, Menu, Typography, Checkbox, Tag, Radio } from 'antd';
import ReactDatePicker from '../datepicker/ReactDatePicker';
import MultipleSelect from '../select/MultipleSelect';
import RadioSelect from '../select/RadioSelect';
import RadioSelectFilter from '../select/RadioSelectFilter';
import Icons from "../../utils/icons";

const FilterDropdown = (props) => {
    const {
        title,
        isStartDateFilter,
        isEndDateFilter,
        isStatusFilter,
        isBusinessTypeFilter,
        isServiceCategoryFilter,
        isTechnicianTypeFilter,
        isServiceRateFilter,
        isVariationFilter,
        isVehicleCategoryFilter,
        isWarentyFilter,
        isStockFilter,
    } = props;
    const dispatch = useDispatch();
    const isReset = useSelector((state) => state.resetOption);
    const isShow = useSelector((state) => state.dropDownToggle);
    const menuRef = useRef(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [visible, setVisible] = useState(false);

    const handleStartDateCallback = ( date ) => {
        setStartDate((previousState) => {
            return { ...previousState, start_date: date };
        });
    };

    const handleEndDateCallback = ( date ) => {
        setEndDate((previousState) => {
            return { ...previousState, end_date: date };
        });
    };

    const businessTypesOptions = [
        { name: "Service Station", id: 1 },
        { name: "Mechanical Repair", id: 2 },
        { name: "Accidental Repair", id: 3 },
        { name: "Spare Parts", id: 4 }
    ];
  
    const serviceCategoryOptions = [
        { name: "All", id: 1 },
        { name: "STD Remove and Replace Labor", id: 2 },
        { name: "STD Remove and Reinstall Labor", id: 3 },
        { name: "STD Paint Process", id: 4 },
        { name: "Non Standard Repair", id: 5 },
        { name: "PDI and Other Fixed Costs", id: 6 },
    ];

    const technicianOptions = [
        { name: "All", id: 1 },
        { name: "Mechanic", id: 2 },
        { name: "Tinker", id: 3 },
        { name: "Painter", id: 4 },
    ];

    const rateOption=[
        { name: "Mechanic", id: 1 },
        { name: "Tinker", id: 2 },
        { name: "Painter", id: 3 },
        { name: "Detailing", id: 4 },
    ];

    const vehiclecategory=[
        { name: "All", id: 1 },
        { name: "SEDAN", id: 2 },
        { name: "VAN", id: 3 },
        { name: "SUV", id: 4 },
    ]

    const variationOption = [
        { label: "N/A", value: 1 },
        { label: "Left Hand", value: 2 },
        { label: "Right Hand", value: 3 },
        { label: "Front", value: 4 },
        { label: "Rear", value: 5 },
    ];
    
    const handleClose = () => {
        setVisible(false);
    };

    const menu = (
        <Menu className="p-4 filter-menu filter-pop">
            <div className="pop-head">
                <Typography.Title level={5} style={{ marginBottom: 0 }}>{title}</Typography.Title>
                <img className='filter-close' onClick={handleClose} src={Icons.close_icon} alt="Filter Icon" />
            </div>

            {isStartDateFilter ?
                <div className="mb-3">
                    <label>Start Date</label>
                    <ReactDatePicker onChangeDate={handleStartDateCallback} /> 
                </div>
            : null}

            {isEndDateFilter ?
                <div className="mb-3">
                    <label>End Date</label>
                    <ReactDatePicker onChangeDate={handleEndDateCallback} /> 
                </div>
            : null}

            {isStatusFilter ?
                <div className="mb-3">
                    <label>Status</label>
                    <Radio.Group className="custom-radio-group d-flex">
                        <Radio.Button value="active" style={{ textAlign: 'center' }}>Active</Radio.Button>
                        <Radio.Button value="inactive" style={{ textAlign: 'center' }}>Inactive</Radio.Button>
                    </Radio.Group>
                </div>
            : null}

            {isBusinessTypeFilter ?
                <div className="mb-3 pop-up-line">
                    <label>Business Type</label>
                    <MultipleSelect 
                        placeholder="Select Business Type"
                        options={businessTypesOptions}
                        maxTagsToShow='2'
                        listStyle={{ 
                            marginTop: '10px', 
                        }}
                    />
                </div>
            : null}

            {isServiceCategoryFilter ?
                <div className="mb-3 ">
                    <label>Service Category</label>
                    <MultipleSelect 
                        placeholder=""
                        options={serviceCategoryOptions}
                        maxTagsToShow='2'
                        listStyle={{ 
                            marginTop: '10px', 
                        }}
                    />
                </div>
            : null}

            {isTechnicianTypeFilter ?
                <div className="mb-3 ">
                    <label>Technician Type</label>
                    <MultipleSelect 
                        placeholder=""
                        options={technicianOptions}
                        maxTagsToShow='2'
                        listStyle={{ 
                            marginTop: '10px', 
                        }}
                    />
                </div>
            : null}

            {isServiceRateFilter ?
                <div className="mb-3 ">
                    <label>Service Rate Type</label>
                    <MultipleSelect 
                        placeholder=""
                        options={rateOption}
                        maxTagsToShow='2'
                        listStyle={{ 
                            marginTop: '10px', 
                        }}
                    />
                </div>
            : null}

            {isVariationFilter ? (
                <div className="mb-3 variation-filter">
                    <label>Variation</label>
                    <RadioSelectFilter
                        placeholder=""
                        options={variationOption}
                        maxTagsToShow="2"
                        listStyle={{
                            marginTop: '10px',
                        }}
                    />
                </div>
            ) : null}

            {  isVehicleCategoryFilter ?
                <div className="mb-3 ">
                    <label>Vehicle Category</label>
                    <MultipleSelect 
                        placeholder=""
                        options={vehiclecategory}
                        maxTagsToShow='2'
                        listStyle={{ 
                            marginTop: '10px', 
                        }}
                    />
                </div>
            : null}

            {isWarentyFilter ?
                <div className="mb-3">
                    <label>Warranty</label>
                    <Radio.Group className="custom-radio-group d-flex">
                        <Radio.Button className='applied' value="available" style={{ textAlign: 'center' }}>Applicable</Radio.Button>
                        <Radio.Button className='not-applicable' value="notapplicable" style={{ textAlign: 'center' }}>Not Applicable</Radio.Button>
                    </Radio.Group>
                </div>
            : null}

        {isStockFilter ?
                <div className="mb-3 pop-up-line">
                    <label>Stock Status</label>
                    <Radio.Group className="custom-radio-group d-flex">
                        <Radio.Button className='radio-available' value="available" style={{ textAlign: 'center' }}>Available</Radio.Button>
                        <Radio.Button className='out-of-stock' value="outofstock" style={{ textAlign: 'center' }}>Out of Stock</Radio.Button>
                    </Radio.Group>
                </div>
            : null}

            <div className="d-flex justify-content-between filter-pop-btn mt-4">
                <Button onClick={handleClose} type="secondary" className='pop-cancel-btn'>
                    Cancel
                </Button>
                <Button type="primary" className='pop-filter-btn'>
                    Filter Now
                </Button>
            </div>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            ref={menuRef}
            visible={visible}
            onVisibleChange={setVisible}
            className="filter-dropdown"
            getPopupContainer={trigger => trigger.parentNode}
        >
            <button className="" id="filterDropdown">
                <img src={Icons.funnel_simple} alt="Filter Icon" />
                <span className="filter-text ms-2">Filter</span>
            </button>
        </Dropdown>
    );
};

const mapStateToProps = (state) => {
    const { base } = state;
    return { base };
};

export default connect(mapStateToProps)(FilterDropdown);
