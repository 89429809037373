//API Base URL
export const apiBaseURL = {
    USERS: "/users",
    CHANGE_PASSWORD: "change-password",
    ADMIN_FORGOT_PASSWORD: "identity-service/send-reset-password-link",
    ADMIN_RESET_PASSWORD: "identity-service/reset-password",
    JOB_STATUS_COUNT: "job-management-service/get-status-count",
    AVAILABLE_JOBS: "job-management-service/get-dashboard-details",
    MY_JOBS: "job-management-service/by-technician-id",
    JOB_OVERVIEW: "job-management-service/by-job-id",
    TECHNICIAN_ID: "user-management-service/get-user-by-email/",
    TECHNICIAN_PROFILE: "user-management-service/get-technician-portal-essential-data-by-id/",
    CLAIM_JOB: "job-management-service/save-technician-job",
    UPDATE_JOB_STATUS: "job-management-service/update-technician-job-status",
    CHECKLIST_BY_SERVICE: "service-management-service/get-check-list/by-service-id",
    ALL_VENDORS: "vendor-management-service/get-all-vendors-list",
    ADD_VENDOR_DETAILS: "vendor-management-service/save-vendor",
    VENDOR_DETAILS_BY_ID: "vendor-management-service/get-vendor-view-details-by-id",
    UPDATE_VENDOR_DETAILS_BY_ID: "vendor-management-service/update-vendor",
    DELETE_VENDOR_DETAILS_BY_ID: "vendor-management-service/delete-vendor",
    VENDOR_BUSINESS_ACCOUNT_DETAILS: "vendor-management-service/vendor-account-details",
    UPLOAD_VENDOR_IMAGE: "metadata-service/upload-vendor-details",
    DOWNLOAD_NIC_IMAGE: "metadata-service/view-images",
    VIEW_IMAGES: "metadata-service/get-details",
    DOWNLOAD_VENDOR_EXCEL_REPORT: "report-management-service/download-vendor",
    GET_ALL_COUNTRIES: "metadata-service/get-all-country-list",
    GET_ALL_PROVINCE_LIST: "metadata-service/get-all-province-list",
    GET_ALL_DISTRICT_LIST: "metadata-service/get-all-district-list",
    GET_ALL_CITY_LIST: "metadata-service/get-all-city-list",
    GET_ALL_BUSINESS_TYPES_LIST: "metadata-service/business-type-all-list",
    GET_ALL_MOBILE_CODES: "metadata-service/get-all-mobile-code-list",
    GENERATE_VENDOR_ID: "vendor-management-service/generate-vendor-id",
    ADD_SERVICE_DETAILS: "service-management-service/save-service",
    GET_SERVICE_DETAILS_BY_ID: "service-management-service/get-particular-details-by-id",
    ALL_SERVICES: "service-management-service/get-all-services-list",
    DOWNLOAD_SERVICE_EXCEL_REPORT: "report-management-service/download-services",
    BULK_SERVICE_UPLOAD: "service-management-service/save-service-bulk-list",
    GET_ALL_SERVICE_TYPES: "cache-management-service/service-type-get-all",
    GET_ALL_SERVICE_CATEGORY: "cache-management-service/service-category-get-all",
    GET_ALL_TECHNICAN_TYPES: "cache-management-service/technician-type-get-all",
    GET_ALL_SERVICE_RATE_TYPES: "cache-management-service/rate-type-get-all",
    ALL_SPARE_PARTS: "spare-parts-management-service/get-all-spare-parts-list",
    EDIT_PROFILE: "edit-profile",
    UPDATE_PROFILE: "update-profile",
};

export const authActionType = {
    LOGIN_USER: "LOGIN_USER",
    LOGOUT_USER: "LOGOUT_USER",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    ADMIN_FORGOT_PASSWORD: "ADMIN_FORGOT_PASSWORD",
    ADMIN_RESET_PASSWORD: "ADMIN_RESET_PASSWORD",
    REFRESH_TOKEN: "REFRESH_TOKEN"
};

export const userActionType = {
    TECHNICIAN_PROFILE: "TECHNICIAN_PROFILE",
    FETCH_USERS: "FETCH_USERS",
    FETCH_USER: "FETCH_USER",
    ADD_USER: "ADD_USER",
    EDIT_USER: "EDIT_USER",
    DELETE_USER: "DELETE_USER",
};

export const profileActionType = {
    FETCH_PROFILE: "FETCH_PROFILE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
};

export const configActionType = {
    FETCH_CONFIG: "FETCH_CONFIG",
    FETCH_ALL_CONFIG: "FETCH_ALL_CONFIG",
};

export const dateLabelSelector = {
    CLEAN: "clean",
    TODAY: "today",
    THIS_WEEK: "this_week",
    LAST_WEEK: "last_week",
    THIS_MONTH: "this_month",
    LAST_MONTH: "last_month",
    CUSTOM: "custom",
};

export const Filters = {
    PAGE: 1,
    OBJ: {
        page: 1,
        size: 4,
        order: "asc",
        searchTerm: "",
    },
};

export const constants = {
    SET_TOTAL_OPEN_JOBS_RECORD: "SET_TOTAL_OPEN_JOBS_RECORD",
    UPDATE_TOTAL_OPEN_JOBS_RECORD_AFTER_CLAIM: "UPDATE_TOTAL_OPEN_JOBS_RECORD_AFTER_CLAIM",
    UPDATE_TOTAL_OPEN_JOB_RECORD_AFTER_ADD: "UPDATE_TOTAL_OPEN_JOB_RECORD_AFTER_ADD",
    SET_TOTAL_MY_JOBS_RECORD: "SET_TOTAL_MY_JOBS_RECORD",
    UPDATE_TOTAL_MY_JOBS_RECORD_AFTER_DELETE: "UPDATE_TOTAL_MY_JOBS_RECORD_AFTER_DELETE",
    UPDATE_TOTAL_MY_RECORD_AFTER_ADD: "UPDATE_TOTAL_MY_JOBS_RECORD_AFTER_ADD",
    IS_LOADING: "IS_LOADING",
    SET_LANGUAGE: "SET_LANGUAGE",
    DATE_ACTION: "DATE_ACTION",
    SET_DATE_FORMAT: "SET_DATE_FORMAT",
    SET_SAVING: "SET_SAVING",
};

export const dateFormat = {
    DEFAULT_MOMENT: "YYYY-MM-DD hh:mm:ss",
    NATIVE: "YYYY-MM-DD",
    CHART_DATE: "YYYY/MM/DD",
    CHART_CUSTOM_DATE: "MMM_YYYY",
};

export const toastType = {
    ADD_TOAST: "ADD_TOAST",
    REMOVE_TOAST: "REMOVE_TOAST",
    ERROR: "error",
};

export const Tokens = {
    ADMIN: "auth_token",
    VENDOR: "vendor_token",
    REFRESH: "refresh_token",
    USER: "user",
    USER_ID: "user_id",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    TECHNICIAN_ID: "technician_id",
    EMAIL: "email_id",
    IMAGE: "image",
    USER_IMAGE_URL: "user_image_url",
    UPDATED_EMAIL: "updated_email",
    UPDATED_FIRST_NAME: "updated_first_name",
    UPDATED_LAST_NAME: "updated_last_name",
    LANGUAGE: "language",
    UPDATED_LANGUAGE: "updated_language",
};

export const jobStatusCountActionType = {
    JOB_STATUS_COUNT: "JOB_STATUS_COUNT",
};

export const jobsActionType = {
    FETCH_AVAILABLE_JOBS: "FETCH_AVAILABLE_JOBS",
    VIEW_JOB: "VIEW_JOB",
    MY_JOBS: "MY_JOBS",
    CLAIM_JOB: "CLAIM_JOB",
    UPDATE_JOB_STATUS: "UPDATE_JOB_STATUS",
    CHECKLIST_BY_SERVICE: "CHECKLIST_BY_SERVICE"
};

export const errorMessage = {
    TOKEN_NOT_PROVIDED: "Token not provided",
    TOKEN_EXPIRED: "Token has expired",
    TOKEN_INVALID:
        "Could not decode token: Error while decoding to JSON: Syntax error",
    TOKEN_INVALID_SIGNATURE: "Token Signature could not be verified.",
};

export const settingsKey = {
    LANGUAGE: "language",
    DEFAULT_LOCALE: "en",
    LOCAL_GERMAN: "gr",
};

export const languageOptions = [
    {
        id: "en",
        name: "settings.select.language.english.label",
        display_name: "English",
    },
];

export const languageFileOptions = [
    { id: 1, name: "language.json" },
    { id: 2, name: "messages.php" },
    { id: 3, name: "Error Messages" },
    { id: 4, name: "Success Messages" },
    { id: 5, name: "Pdf Messages" },
];
