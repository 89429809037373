import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../config/apiConfig';
import { apiBaseURL, toastType, Tokens } from "../constants";
import { addToast } from './toastSlice';

export const fetchMyProfile = createAsyncThunk(
    'profile/fetchMyProfile',
    async (_, { dispatch }) => {
        const technicianEmail = localStorage.getItem(Tokens.EMAIL);
        try {
            const profileResponse = await apiConfig.get(apiBaseURL.TECHNICIAN_ID + technicianEmail);
            const response = await apiConfig.get(apiBaseURL.TECHNICIAN_PROFILE + profileResponse.data.id);
            return response.data; 
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Failed to fetch profile.";
            dispatch(addToast({ text: errorMessage, type: toastType.ERROR }));
            throw new Error(errorMessage); 
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        myProfile: [],
        isProfileLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyProfile.pending, (state) => {
                state.isProfileLoading = true;
                state.error = null;
            })
            .addCase(fetchMyProfile.fulfilled, (state, action) => {
                state.isProfileLoading = false;
                state.myProfile = action.payload;
            })
            .addCase(fetchMyProfile.rejected, (state, action) => {
                state.isProfileLoading = false;
                state.error = action.error.message;
            })
    },
});

export default profileSlice.reducer;
