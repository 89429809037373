import React from 'react';
import { useNavigate } from 'react-router-dom';
import MasterLayout from '../../MasterLayout';
import { Filters } from '../../../constants';
import { getFormattedMessage, placeholderText } from '../../../shared/sharedMethod';
import TabTitle from '../../../shared/tab-title/TabTitle';
import Breadcrumb from '../../../shared/breadcrumb/Breadcrumb';
import SparePartForm from './SparePartForm';

const CreateSparePart = () => {
    const navigate = useNavigate();

    const addSparePartData = (formValue) => {
        addSparePart(formValue, navigate, Filters.OBJ);
    };

    return (
        <MasterLayout>
            <TabTitle title={placeholderText("spare-parts.create.title")} />
            <Breadcrumb
                parent_menu="Spare Parts & Service Management"
                parent_menu_url="#/admin/spare-parts"
                second_level_page="Spare Parts"
                second_level_page_url="#/admin/spare-parts"
                current_page="Add New Spare Part Details"
                current_page_url=""
            />
            <SparePartForm addSparePartData={addSparePartData} />
        </MasterLayout>
    )
};

export default CreateSparePart;
