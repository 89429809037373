import React from "react";
import { Modal } from "antd";
import Icons from "../../utils/icons";

const AddModalInput = ({ isModalOpen, handleModalClose, options, modalTitle, placeholderText,  }) => (
    <Modal
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        centered
        width={350}
        title={<h3>{modalTitle}</h3>}
        className="add-modal"
    >
        <div className="add-unit-form">
            <input type="text" placeholder={placeholderText} className="add-unit-input" />
            <div className="add-unit-form-buttons">
                <button className="button add-unit-submit">
                    <h5>Submit</h5>
                </button>
            </div>
        </div>
        <div className="add-unit-list">
            {options.map((option) => (
                <div className="add-unit-item" key={option.value}>
                    <span>{option.label}</span>
                    <img src={Icons.trash} alt="Delete icon" />
                </div>
            ))}
        </div>
    </Modal>
);

export default AddModalInput;