import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import MasterLayout from "../MasterLayout";
import Widget from '../dashboard/Widget';
import Icons from "../../utils/icons"
import profile_avatar from "../../assets/images/profile-avatar.png";
import update_image from "../../assets/images/image.svg";
import { getFormattedMessage } from '../../shared/sharedMethod';
import { fetchMyProfile } from '../../slices/profileSlice';
import { useDispatch, useSelector } from 'react-redux';

const ProfileEdit = () => {
  const { myProfile, isProfileLoading, error } = useSelector((state) => state.profile);
  const dispatch = useDispatch(); 

  useEffect(() => {
    dispatch(fetchMyProfile()); 
}, [dispatch]);
 
  return (
    <MasterLayout>
      <Widget formattedMessage={getFormattedMessage} />
      <div className="profile-edit-container">
        <div className="profile-header">
          <Link to="app/dashboard" className="back">
            <img src={Icons.arrow_left} alt="Profile Photo" />
            <h3>Profile Settings</h3>
          </Link>
        </div>
        <div className="profile-card">
          <div className="profile-edit-avatar">
            <div className="profile-photo-edit">
              <img src={profile_avatar} alt="Profile Photo" />
              <div className="profile-info-edit">
                <h4>{myProfile.firstName + ' ' + myProfile.lastName}</h4>
                <p>{myProfile.technicianEmail}</p>
              </div>
            </div>
            <div className="edit-update-photo">
              <a>
                <img src={update_image} alt="Profile Photo" />
                Update Photo
              </a>
            </div>
          </div>
          <div className="profile-edit-details">
            <div className="profile-edit-item">
              <label>First Name</label>
              <input
                type="text"
                id="first-name"
                name="first-name"
                value={myProfile.firstName}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Last Name</label>
              <input
                type="text"
                id="last-name"
                name="last-name"
                value={myProfile.lastName}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Date of Birth</label>
              <input
                type="text"
                id="name"
                name="date"
                value={myProfile.dateOfBirth}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Phone Number</label>
              <input
                type="phonenumber"
                id="phone"
                name="phone-number"
                value={myProfile.contactNumber}
              ></input>
            </div>
            <div className="profile-edit-item">
              <label>Email</label>
              <input
                type="email"
                id="name"
                name="date"
                value={myProfile.technicianEmail}
              ></input>
            </div>
          </div>
          <button className="profile-edit-button">Save Changes</button>
          <button className="profile-cancel-button">Cancel</button>
        </div>
      </div>
    </MasterLayout>
  );
};

export default ProfileEdit;