import React from 'react';
import MasterLayout from '../../MasterLayout';
import {useNavigate} from 'react-router-dom';
import {Filters} from '../../../constants';
import ServiceForm from './ServiceForm';
import {getFormattedMessage, placeholderText} from '../../../shared/sharedMethod';
import TabTitle from '../../../shared/tab-title/TabTitle';
import Breadcrumb from '../../../shared/breadcrumb/Breadcrumb';

const CreateService = () => {
    const navigate = useNavigate();

    const addServiceData = (formValue) => {
        addService(formValue, navigate, Filters.OBJ);
    };

    return (
        <MasterLayout>
            <TabTitle title={placeholderText("services.create.title")} />
            <Breadcrumb 
                parent_menu="Spare Parts & Service Management"
                parent_menu_url="#/admin/services"
                second_level_page="Services" 
                second_level_page_url="#/admin/services" 
                current_page="Add New Service Details" 
                current_page_url="" 
            />
            <ServiceForm addServiceData={addServiceData}/>
        </MasterLayout>
    )
};

export default CreateService;
