import React, { useState, useEffect } from 'react';
import { Checkbox, Tag } from 'antd';
import Icons from "../../utils/icons";

const MultipleSelect = ( {
    isRequired,
    placeholder,
    listStyle,
    maxTagsToShow,
    value = null,
    defaultValue = null,
    onChange,
    errors = "",
    options,
}) => {

    const [showCheckboxes, setShowCheckboxes] = useState(false);

    const toggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
    };

    const handleRemove = (id) => {
        setSelectedOptions(selectedOptions.filter((typeId) => typeId !== id));
    };

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelect = (id) => {
        if (!selectedOptions.includes(id)) {
            setSelectedOptions([...selectedOptions, id]);
        }
    };

    useEffect(() => {
        if (selectedOptions.length === 0) {
            setShowCheckboxes(false);
        }
    }, [selectedOptions]);

    return (
        <div className="multiple-select">
            <div className="selected-business-box" onClick={toggleCheckboxes}>
                {selectedOptions.length === 0 && (
                    <span style={{ color: '#999' }}>
                        {placeholder}
                    </span>
                )}

                {selectedOptions.slice(0, maxTagsToShow).map((id) => (
                    <Tag
                        key={id}
                        closable
                        onClose={() => handleRemove(id)}
                        style={{
                            borderRadius: '12px',
                            padding: '4px 8px',
                            lineHeight: 'inherit',
                            height: '28px',
                            border: '1px solid #E4EAF2'
                        }}
                    >
                        {options.find((option) => option.id === id).name}
                    </Tag>
                ))}

                {selectedOptions.length > maxTagsToShow && (
                    <Tag style={{ height: '28px', borderRadius: '12px', width: '33px', padding: '4px 8px' }}>
                        +{selectedOptions.length - maxTagsToShow}
                    </Tag>
                )}

                <img
                    src={showCheckboxes ? Icons.caret_up : Icons.caret_black_down}
                    alt="Toggle Checkbox"
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '11px'
                    }}
                />
            </div>

            {showCheckboxes && (
                <div className="business-checkbox-list" style={listStyle} >
                    {options.map((option) => (
                        <Checkbox
                            key={option.id}
                            onChange={(e) =>
                                e.target.checked ? handleSelect(option.id) : handleRemove(option.id)
                            }
                            checked={selectedOptions.includes(option.id)}
                        >
                            {option.name}
                        </Checkbox>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultipleSelect;
