import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiConfig from '../config/apiConfig';
import { apiBaseURL } from '../constants';
import _ from 'lodash';

export const fetchAllRateCards = createAsyncThunk(
    'rateCards/fetchAllRateCards',
    async () => {
        const response = await apiConfig.get(apiBaseURL.GET_ALL_SERVICE_RATE_TYPES);
        return response.data;
    }
);

const rateCardSlice = createSlice({
    name: 'rateCards',
    initialState: {
        isRateCardsLoading: false,
        allRateCards: [],
        totalRecord: 0,
        totalPages: 0,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRateCards.pending, (state) => {
                state.isRateCardsLoading = true;
                state.error = null;
            })
            .addCase(fetchAllRateCards.fulfilled, (state, action) => {
                state.isRateCardsLoading = false;
                state.allRateCards = action.payload.data;
                state.totalRecord = action.payload.totalItems;
                state.totalPages = action.payload.totalPages
            })
            .addCase(fetchAllRateCards.rejected, (state, action) => {
                state.isRateCardsLoading = false;
                state.error = action.error.message;
                console.error('Fetch rate cards failed:', action.error);
            })
    },
});

export default rateCardSlice.reducer;
