import React, { useState, useEffect} from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../MasterLayout";
import ReactDataTable from "../../../shared/table/ReactDataTable";
import DeleteService from "./DeleteService";
import TabTitle from "../../../shared/tab-title/TabTitle";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllServices } from '../../../slices/serviceSlice';
import {
    getFormattedMessage,
    placeholderText,
} from "../../../shared/sharedMethod";
import TopProgressBar from "../../../shared/components/loaders/TopProgressBar";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import Spinner from "../../../shared/components/loaders/Spinner";
import ActionButton from "../../../shared/action-buttons/ActionButton";

const Services = () => {
    const { isServicesLoading, allServices, totalRecord, totalPages, error } = useSelector((state) => state.services);
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllServices());
      }, [dispatch]);
  
    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };

    const onChange = (filter) => {
       // allVendors(filter, true);
    };

    const goToEditService = (item) => {
        const id = item.id;
        navigate(`/admin/vendors/edit/${id}`);
    };

    const goToServiceDetailPage = (VendorId) => {
        window.location.href = "#/app/vendors/detail/" + VendorId;
    };

    const itemsValue =
    allServices.length >= 0 &&
    allServices.map((vendor, index) => ({
        no: String(index + 1).padStart(2, '0'),
        vendor_id: vendor.vendorId,
        first_name: vendor.firstName,
        last_name: vendor.lastName,
        mobile: vendor.mobileNumber,
        email: vendor.emailAddress,
        nic: vendor.nic,
        business_name: vendor.businessName,
        business_address_line1: vendor.businessAddressLine1,
        district: vendor.district,
        province: vendor.province,
        telephone: vendor.businessTelephone,
        business_email: vendor.businessEmail,
        business_type: vendor.businessTypeSaveRequestDtoList.map(type => type.businessRateTypeName || type.businessPrimaryRateTypeId),
        joined_date: vendor.joinDate,
        status: vendor.status ? "Active" : "Inactive",
        registered_by: vendor.registeredBy
    }));

    const columns = [
        {
            id: 1,
            name: "No",
            selector: (row) => row.no,            
            sortField: "no",
            sortable: false,
            maxWidth: '100px',
            minWidth: '70px',
        },
        {
            id: 2,
            name: "Service ID",
            selector: (row) => row.vendor_id,
            sortField: "vendor_id",
            sortable: true,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 3,
            name: "Service Name",
            selector: (row) => row.first_name,
            sortField: "first_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 4,
            name: "Service Category",
            selector: (row) => row.last_name,
            sortField: "last_name",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 5,
            name: "Part Required",
            selector: (row) => row.mobile,
            sortField: "mobile",
            sortable: false,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
            grow: 3,
            cell: (row) => (
                <span className={`part-required ${row.mobile === 'Yes' ? 'yes' : 'no'}`}>
                    {row.mobile}
                </span>
            ),
        },      
        {
            id: 6,
            name: "Technician Type",
            selector: (row) => row.email,
            sortField: "email",
            sortable: true,
            wrap: true,
            maxWidth: '600px',
            minWidth: '300px',
            grow: 3
        },
        {
            id: 7,
            name: "Hours",
            selector: (row) => row.nic,
            sortField: "nic",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 8,
            name: "Service Rate Type",
            selector: (row) => row.business_name,
            sortField: "business_name",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 9,
            name: "Total Service Cost",
            selector: (row) => row.business_address_line1,
            sortField: "businessAddressLine1",
            sortable: false,
            maxWidth: '600px',
            minWidth: '200px',
            wrap: true,
            grow: 3
        },
        {
            id: 10,
            name: "Service Primary Identifier",
            selector: (row) => row.district,
            sortField: "district",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 11,
            name: "Service Created By",
            selector: (row) => row.province,
            sortField: "province",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 12,
            name: "Last Updated",
            selector: (row) => row.telephone,
            sortField: "telephone",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        }, 
        {
            id: 18,
            name: "",
            selector: (row) => null, 
            sortable: false,
            maxWidth: '400px',
            minWidth: '130px',
            cell: (row) => (
                <div className="actions" >
                    <ActionButton
                        isViewIcon={true}
                        goToDetailScreen={goToEditService}
                        item={row}
                        goToEditData={goToEditService}
                        isEditMode={true}
                        onClickDeleteModel={onClickDeleteModel}
                    />
                </div>
            ),
        },
    ];

    return (
        <MasterLayout>
            <Breadcrumb 
                parent_menu="Spare Parts & Service Management"
                parent_menu_url="#/admin/services"
                current_page="Services" 
                current_page_url="" 
            />
            <TopProgressBar />
            <TabTitle title={placeholderText("services.title")} />
            {isServicesLoading ? <Spinner /> : <>
                <ReactDataTable
                    columns={columns}
                    tableTitle={getFormattedMessage("services.title")}
                    countTextSingular={getFormattedMessage("services.count.title.singular")}
                    countTextPlural={getFormattedMessage("services.count.title.plural")}
                    filterTitle={getFormattedMessage("services.filter.title")}
                    isServiceCategoryFilter
                    isTechnicianTypeFilter
                    isServiceRateFilter
                    items={itemsValue}
                    onChange={onChange}
                    isLoading={isServicesLoading}
                    isShowFilterField
                    isShowSearch={true}
                    ButtonValue={getFormattedMessage("services.create.button.title")}
                    totalRows={totalRecord}
                    totalPages={totalPages}
                    noDataText={getFormattedMessage("services.no-record-found.text")}
                    isEXCEL ={true}
                    to="#/admin/services/create"
                />
            </>
            }
            <DeleteService
                onClickDeleteModel={onClickDeleteModel}
                deleteModel={deleteModel}
                onDelete={isDelete}
            />
        </MasterLayout>
    );
};

export default Services;