import React from 'react';
import {Button} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAdd} from '@fortawesome/free-solid-svg-icons';

const TableButton = ({ButtonValue, to, className, style}) => {
    return(
        <Button type='primary' href={to} className={className} style={style} >
            <FontAwesomeIcon icon={faAdd} /> 
            {ButtonValue}
        </Button>
    )
}

export default TableButton;
