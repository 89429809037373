import React, { useState } from 'react';
import Select from 'react-select';
import { Button } from 'antd';
import Icons from '../../utils/icons';
import AddModalInput from './AddModalInput';

const CustomMultipleSelect = ({ options, dropdownPlaceholderText, modalTitle, modalPlaceholderText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionChange = (selected) => {
    setSelectedOptions(selected || []);
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter((opt) => opt.value !== optionToRemove.value);
    setSelectedOptions(updatedOptions);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
    setIsDropdownOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className="checkbox-option"
      >
        <input
          type="checkbox"
          checked={isSelected}
        />
        <label>{data.label}</label>
      </div>
    );
  };

  return (
    <div className="dropdown custom-multi-dropdown">
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt="chevron-down" className={isDropdownOpen ? 'rotate' : ''} />}
        iconPosition="end"
        className={`dropdown-button ${isDropdownOpen ? 'opened' : ''} ${selectedOptions.length ? 'selected' : ''}`}
      >
        <div className="selected-options">
          {selectedOptions.length === 0
            ? dropdownPlaceholderText
            : selectedOptions.slice(0, 3).map((opt) => (
              <div key={opt.value} className="tag-container">
                <span className="tag-label">{opt.label}</span>
                <img
                  src={Icons.close_icon}
                  alt="remove"
                  className="tag-remove-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveOption(opt);
                  }}
                />
              </div>
            ))}
          {selectedOptions.length > 3 && (
            <div className="tag-container">
              <span className="tag-label">+{selectedOptions.length - 3}</span>
            </div>
          )}
        </div>
      </Button>
      {isDropdownOpen && (
        <>
          <div className="dropdown-menu">
            <Select
              components={{ Option: CustomOption }}
              options={options}
              value={selectedOptions}
              onChange={handleOptionChange}
              isMulti={true}
              menuIsOpen={true}
              isClearable={false}
              isSearchable={false}
              tabSelectsValue={false}
              hideSelectedOptions={false}
              backspaceRemovesValue={false}
              closeMenuOnSelect={false}
            />
            <div className="dropdown-footer">
              <button
                className="button add-option-button"
                onClick={handleModalOpen}
              >
                <h5>Add Category</h5>
              </button>
            </div>
          </div>
          <div
            onClick={toggleOpen}
            className="dropdown-blanket"
          />
          {isModalOpen && (
            <AddModalInput
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              options={options}
              modalTitle={modalTitle}
              placeholderText={modalPlaceholderText}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CustomMultipleSelect;
