import React, { useState } from "react";
import {
    PhoneInput,
    defaultCountries,
    parseCountry,
  } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import 'react-international-phone/style.css';
import Icons from "../../utils/icons";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
};

const countries = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ['lk', 'us', 'gb'].includes(iso2);
});

const TelephoneInput = ({
    placeholder,
}) => {
    const [phone, setPhone] = useState('');
    const isValid = isPhoneValid(phone);

    return (
        <>
        <PhoneInput
            defaultCountry="lk"
            value={phone}
            onChange={(phone) => setPhone(phone)}
            countries={countries}
        />
        {/* {!isValid && 
            <span>Phone is not valid</span>
        } */}
        </>
    );
};

export default TelephoneInput;
