import React, { useState, useEffect} from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../MasterLayout";
import ReactDataTable from "../../../shared/table/ReactDataTable";
import DeleteSparePart from "./DeleteSparePart";
import TabTitle from "../../../shared/tab-title/TabTitle";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllSpareParts } from '../../../slices/sparePartSlice';
import { Tooltip } from 'antd';
import {
    getFormattedMessage,
    placeholderText,
} from "../../../shared/sharedMethod";
import TopProgressBar from "../../../shared/components/loaders/TopProgressBar";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import Spinner from "../../../shared/components/loaders/Spinner";
import ActionButton from "../../../shared/action-buttons/ActionButton";

const SpareParts = () => {
    const { isSparePartsLoading, allSpareParts, totalRecord, totalPages, error } = useSelector((state) => state.spareparts);
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllSpareParts());
      }, [dispatch]);
  
    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };

    const onChange = (filter) => {
       // allVendors(filter, true);
    };

    const goToEditSparePart = (item) => {
        const id = item.id;
        navigate(`/admin/vendors/edit/${id}`);
    };

    const goToSparePartDetailPage = (SparePartsId) => {
        window.location.href = "#/admin/spare-parts/details" + SparePartsId;
    };

    const itemsValue =
    allSpareParts.length >= 0 &&
    allSpareParts.map((vendor, index) => ({
        no: String(index + 1).padStart(2, '0'),
        vendor_id: vendor.vendorId,
        first_name: vendor.firstName,
        last_name: vendor.lastName,
        mobile: vendor.mobileNumber,
        email: vendor.emailAddress,
        nic: vendor.nic,
        business_name: vendor.businessName,
        business_address_line1: vendor.businessAddressLine1,
        district: vendor.status ? "Applicable" : "Non Applicable",
        province: vendor.province,
        telephone: vendor.businessTelephone,
        business_email: vendor.businessEmail,
        business_type: vendor.businessType,
        registered_by: vendor.registeredBy,
        status: vendor.status ? "Available" : "Out of Stock",
    }));

    const columns = [
        {
            id: 1,
            name: "No",
            selector: (row) => row.no,            
            sortField: "no",
            sortable: false,
            maxWidth: '100px',
            minWidth: '70px',
        },
        {
            id: 2,
            name: "SKU Code",
            selector: (row) => row.vendor_id,
            sortField: "vendor_id",
            sortable: true,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 3,
            name: "Part Name",
            selector: (row) => row.first_name,
            sortField: "first_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 4,
            name: "Part Number",
            selector: (row) => row.last_name,
            sortField: "last_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 5,
            name: "Part Category",
            selector: (row) => row.mobile,
            sortField: "mobile",
            sortable: false,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
            grow: 3
        },
        {
            id: 9,
            name: "Stock Status",
            selector: (row) => row.status,
            sortField: "Status",
            sortable: false,
            maxWidth: '600px',
            minWidth: '200px',
            wrap: true,
            grow: 3,
            cell: (row) => (
                <div>
                    <span
                        className={`status-badge ${
                            row.status === "Available" ? "available" : "not-available"
                        }`}
                    >
                        {row.status}
                    </span>
                </div>
            ),
        },
        {
            id: 6,
            name: "Variation",
            selector: (row) => row.email,
            sortField: "email",
            sortable: false,
            wrap: true,
            maxWidth: '600px',
            minWidth: '150px',
            grow: 3
        },
        {
            id: 7,
            name: "Unit and Quantity of Purchase ",
            selector: (row) => row.nic,
            sortField: "nic",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '180px',
            wrap: true,
        },
        {
            id: 8,
            name: "Unit and Quantity of Sale",
            selector: (row) => row.business_name,
            sortField: "business_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 10,
            name: "Warranty",
            selector: (row) => row.district,
            sortField: "district",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
            cell: (row) => (
                <span className={`warrenty ${row.district === 'Applicable' ? 'applicable' : 'non-applicable'}`}>
                    {row.district}
                </span>
            ),
        },
        {
            id: 11,
            name: "Vehicle Category",
            selector: (row) => row.province,
            sortField: "province",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 12,
            name: "Purchase Price",
            selector: (row) => row.telephone,
            sortField: "telephone",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 13,
            name: "Sale Price (MRP)",
            selector: (row) => row.business_email,
            sortField: "business_email",
            sortable: false,
            grow: 3,
            maxWidth: '300px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 14,
            name: "Spare Parts Primary Identifier",
            selector: (row) => row.business_type,
            sortable: false,
            maxWidth: '200px',
            minWidth: '180px',
            wrap: true,
        },            
        {
            id: 15,
            name: "Spare Parts Added By",
            selector: (row) => row.registered_by,
            sortField: "registered_by",
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '180px',
        },
        {
            id: 18,
            name: "",
            selector: (row) => null, 
            sortable: false,
            maxWidth: '400px',
            minWidth: '130px',
            cell: (row) => (
                <div className="actions" >
                    <ActionButton
                        isViewIcon={true}
                        goToDetailScreen={goToSparePartDetailPage}
                        item={row}
                        goToEditData={goToEditSparePart}
                        isEditMode={true}
                        onClickDeleteModel={onClickDeleteModel}
                    />
                </div>
            ),
        },
    ];

    return (
        <MasterLayout>
            <Breadcrumb 
                parent_menu="Spare Parts & Service Management"
                parent_menu_url="#/admin/spare-parts"
                current_page="Spare Parts" 
                current_page_url="" 
            />
            <TopProgressBar />
            <TabTitle title={placeholderText("spare-part.title")} />
            {isSparePartsLoading ? <Spinner /> : <>
                <ReactDataTable
                    columns={columns}
                    tableTitle={getFormattedMessage("spare-part.title")}
                    countTextSingular={getFormattedMessage("spare-part.count.title.singular")}
                    countTextPlural={getFormattedMessage("spare-part.count.title.plural")}
                    filterTitle={getFormattedMessage("spare-part.filter.title")}
                    isVariationFilter
                    isVehicleCategoryFilter
                    isWarentyFilter
                    isStockFilter
                    items={itemsValue}
                    onChange={onChange}
                    isLoading={isSparePartsLoading}
                    isShowFilterField
                    isShowSearch={true}
                    ButtonValue={getFormattedMessage("spare-part.create.button.title")}
                    totalRows={totalRecord}
                    totalPages={totalPages}
                    noDataText={getFormattedMessage("spare-part.no-record-found.text")}
                    isEXCEL ={true}
                    to="#/admin/spare-parts/create"
                />
            </>
            }
            <DeleteSparePart
                onClickDeleteModel={onClickDeleteModel}
                deleteModel={deleteModel}
                onDelete={isDelete}
            />
        </MasterLayout>
    );
};

export default SpareParts;