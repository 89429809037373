import React, { useState } from "react";
import { Modal } from "antd";
import Icons from "../../../utils/icons";

const EditRateCard = ({ rateCard, onClose }) => {
  const [baseRate, setBaseRate] = useState(rateCard?.baseRate || "");
  const [additionalCharges, setAdditionalCharges] = useState(
    rateCard?.additionalCharges || ""
  );
  const [discount, setDiscount] = useState(rateCard?.discount || "");
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const newErrors = {};
    if (!baseRate) newErrors.baseRate = "Please enter the base rate";

    if (!additionalCharges) {
      newErrors.additionalCharges = "Please enter the additional charges";
    }

    if (!discount) {
      newErrors.discount = "Please enter the discount";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Updated rate card values:", {
        baseRate,
        additionalCharges,
        discount,
      });
      onClose();
    }
  };

  return (
    <Modal
      centered
      title={rateCard?.title}
      visible={true}
      onCancel={onClose}
      footer={[
        <button key="cancel" onClick={onClose} className="cancel-btn">
          Cancel
        </button>,
        <button
          key="save"
          type="primary"
          onClick={handleSave}
          className="save-btn"
        >
          Save
        </button>,
      ]}
      className="ratecard-modal"
      closeIcon={
        <img
          className="custom-close-btn"
          src={Icons.close_icon}
          onClick={onClose}
        />
      }
    >
      <div className="rate-edit-form">
        <div className="form-group">
          <label className="form-label">
            Base Rate/Hour
            <img src={Icons.asterisk} className="required" />
          </label>
          <input
            type="number"
            value={baseRate}
            onChange={(e) => setBaseRate(e.target.value)}
            className="custom-input"
          />
          {errors.baseRate && (
            <div className="error-message">
              <img src={Icons.warning_circle} alt="Warning Icon" />
              <span>{errors.baseRate}</span>
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">
            Additional Charges
            <img src={Icons.asterisk} className="required" />
          </label>
          <input
            value={additionalCharges}
            onChange={(e) => setAdditionalCharges(e.target.value)}
            className="custom-input"
          />
          {errors.additionalCharges && (
            <div className="error-message">
              <img src={Icons.warning_circle} alt="Warning Icon" />
              <span>{errors.additionalCharges}</span>
            </div>
          )}
        </div>
        <div className="form-group">
          <label className="form-label">
            Discount
            <img src={Icons.asterisk} className="required" />
          </label>
          <div className="input-wrapper">
            <input
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              className="custom-input discount"
            />
            <div className="icon-container">
              <img
                src={Icons.percentage}
                className="percentage-icon"
                alt="percentage"
              />
            </div>
          </div>
          {errors.discount && (
            <div className="error-message">
              <img src={Icons.warning_circle} alt="Warning Icon" />
              <span>{errors.discount}</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditRateCard;
