import React, { useState, useEffect, useMemo } from "react";
import { Filters } from "../../constants";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import FilterComponent from "../../shared/components/FilterComponent";
import { renderSortIcons } from "../../config/sortConfig";
import TableButton from "../../shared/action-buttons/TableButton";
import EmptyComponent from "./EmptyComponent";
import FilterDropdown from "../../shared/filterMenu/FilterDropdown";
import PaginationComponent from "./PaginationComponent";
import Icons from "../../utils/icons";

const ReactDataTable = (props) => {
    const {
        columns,
        AddButton,
        items,
        tableTitle,
        countTextSingular,
        countTextPlural,
        ButtonValue,
        noDataText,
        to,
        onChange,
        currentPage,
        pageSize,
        totalPages,
        totalRows,
        isLoading,
        isShowFilterField,
        isStatus,
        filterTitle,
        isStartDateFilter,
        isEndDateFilter,
        isStatusFilter,
        isBusinessTypeFilter,
        isServiceCategoryFilter,
        isTechnicianTypeFilter,
        isServiceRateFilter,
        isVariationFilter,
        isVehicleCategoryFilter,
        isWarentyFilter,
        isStockFilter,
        isExport,
        onExcelClick,
        isShowSearch,
    } = props;
    const [order, setOrder] = useState(Filters.OBJ.order);
    const [direction, setDirection] = useState(Filters.OBJ.order);
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState();
    const [show, setShow] = useState(false);
    const [showCriteria, setCriteriaShow] = useState(false);
    const [selectedData, setSelectedData] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const dispatch = useDispatch();

    const tableColumns = useMemo(() => columns, []);

    const handleSearch = (searchText) => {
       // onChange({ searchTerm: searchText })}
    };

    const handleSelected = (state) => {
        setSelectedData(state.selectedRows);
    };

    const onStartDateChange = (obj) => {
        setStartDate(obj);
    };

    const onEndDateChange = (obj) => {
        setEndDate(obj);
    };

    const customSort = (column, sortDirection) => {
        if (column) {
            setOrder(column.sortField);
            setDirection(sortDirection);
        }
    };

    const clearFilter = () => {
        setCriteriaShow(false);
    };

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
         return(
             <>
                <label className="table-checkbox">
                    <input 
                        type="checkbox" 
                        ref={ref}
                        onClick={ onClick }
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
             </>
         )
    })

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <>
                <div className="table-header d-flex justify-content-between align-items-center">
                    <div className="table-title-section">
                        <span className="table-title">{tableTitle}</span>
                        {totalRows > 0 && (
                            <span className="table-count"> {totalRows.toString().padStart(2, '0')} {totalRows === 1 ? countTextSingular : countTextPlural }</span>
                        )}
                    </div>
                    <div className="add-table-section">
                        {ButtonValue && (
                            <TableButton 
                                ButtonValue={ButtonValue} 
                                to={to} 
                                className="add-new-btn" 
                                style={{ maxwidth: '150px', width: '100%' }}
                            />
                        )}
                    </div>
                </div>
    
                <div className='search-container'>
    
                    {isShowFilterField && (
                        <FilterDropdown
                            title={filterTitle}
                            isStartDateFilter={isStartDateFilter}
                            isEndDateFilter={isEndDateFilter}
                            isStatusFilter={isStatusFilter}
                            isBusinessTypeFilter={isBusinessTypeFilter}
                            isServiceCategoryFilter={isServiceCategoryFilter}
                            isTechnicianTypeFilter={isTechnicianTypeFilter}
                            isServiceRateFilter={isServiceRateFilter}
                            isVariationFilter={isVariationFilter}
                            isVehicleCategoryFilter={isVehicleCategoryFilter}
                            isWarentyFilter={isWarentyFilter}
                            isStockFilter={isStockFilter}
                            onStartDateChange={onStartDateChange}
                            onEndDateChange={onEndDateChange}
                            isStatus={isStatus}
                            show={show}
                        />
                    )}
    
                    {isShowSearch && (
                        <FilterComponent handleSearch={handleSearch} />
                    )}

                    <div className="add-table-section-mobile">
                        {ButtonValue && (
                            <TableButton 
                                ButtonValue={ButtonValue} 
                                to={to} 
                                className="add-new" 
                                style={{ width: '100%' }}
                            />
                        )}
                    </div>
                </div>
    
                {showCriteria && (
                <div className="tag-box">
                    <div className="tags-container">
                        <span className="applied-filter apply-top">Applied Filters:</span>
                        <div className="tag table-pop">
                            <span className="applied-filter">Start Date</span>: 01-09-2024
                                <span className="close-btn" onClick={() => removeTag()}>
                                    ×
                            </span>                            
                        </div>
                        
                        <div className="tag table-pop">
                            <span className="applied-filter">End date</span>: 01-09-2024
                            <span className="close-btn" onClick={() => removeTag()}>
                                ×
                            </span>
                        </div>
                        <div className="tag table-pop">
                            <span className="applied-filter">Status</span>: Active
                            <span className="close-btn" onClick={() => removeTag()}>
                                ×
                            </span>
                        </div>
                    </div>
                    <div className="filter-data">
                        <span className="applied-filter">Bussiness Types:</span>
                        <div className="type">
                            <div className="tag table-pop">
                                Select Station
                                <span className="close-btn" onClick={() => removeTag()}>
                                    ×
                                </span>
                            </div>
                            <div className="tag table-pop">
                                Mechanic Repair
                                <span className="close-btn" onClick={() => removeTag()}>
                                    ×
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="filter-btn-container">
                        <button className="filter-btn" onClick={clearFilter}>
                            Clear All
                        </button>
                    </div>
                </div>
                )}
            </>
        );
    }, [status, isShowSearch, isShowFilterField, AddButton, showCriteria, selectedData]);

    const emptyStateProps = {
        isLoading: isLoading,
        noDataText: noDataText
    };

    return (        
        <div className="data-table pt-0 vendor-table ">
            <div className="header-part">
                <div className="table-subheader">
                    {subHeaderComponentMemo}
                </div>
            </div>
        
            <DataTable
                columns={tableColumns}
                selectableRowsComponent={Checkbox}
                selectableRowsHighlight
                highlightOnHover
                overflowY
                onSelectedRowsChange={handleSelected}
                noDataComponent={<EmptyComponent {...emptyStateProps} />}
                data={items}
                paginationComponent={() => (
                    <PaginationComponent
                        totalPages={totalPages}
                        itemsPerPage={pageSize}
                        currentPage={currentPage}
                        onPageChange={(page) => onChange({ page })}
                        onPageSizeChange={(size) => onChange({ size })}
                        isExport={isExport}
                        onExcelClick={onExcelClick}
                    />
                )}
                sortIcon={renderSortIcons(direction)}
                pagination={true}
                paginationServer={true}
                onSort={customSort}
                defaultSortFieldId={1}
                responsive
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead={true}  
            />
        </div> 
    );
};

ReactDataTable.propTypes = {
    columns: PropTypes.array,
    totalRows: PropTypes.number,
    onChange: PropTypes.func,
    sortAction: PropTypes.func,
};
export default ReactDataTable;
