import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Dropdown, Form } from "antd";
import moment from "moment";
import Icons from "../../../utils/icons";
import * as EmailValidator from "email-validator";
import {
  getFormattedMessage,
  placeholderText,
  numValidate
} from "../../../shared/sharedMethod";
import RadioSelect from "../../../shared/select/RadioSelect";
import CustomOptionsSelect from "../../../shared/select/CustomOptionsSelect";
import CustomSubGroupSelect from "../../../shared/select/CustomSubGroupSelect";
import CustomGroupSelect from "../../../shared/select/CustomGroupSelect";
import CustomMultipleSelect from "../../../shared/select/CustomMultipleSelect";
import UploadComponent from "../../../shared/upload/UploadComponent";
import ReactDatePicker from "../../../shared/datepicker/ReactDatePicker";

const SparePartForm = (props) => {
  const { addCustomerData, id, singleCustomer } = props;
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const [customerValue, setCustomerValue] = useState({
    name: singleCustomer ? singleCustomer[0].name : "",
    dob: singleCustomer
      ? singleCustomer[0].dob === null
        ? null
        : moment(singleCustomer[0].dob).toDate()
      : null,
    email: singleCustomer ? singleCustomer[0].email : "",
    phone: singleCustomer ? singleCustomer[0].phone : "",
    country: singleCustomer ? singleCustomer[0].country : "",
    city: singleCustomer ? singleCustomer[0].city : "",
    address: singleCustomer ? singleCustomer[0].address : "",
  });

  const [errors, setErrors] = useState({
    dob: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
  });

  const handleStartDateCallback = (date) => {
    setStartDate((previousState) => {
      return { ...previousState, start_date: date };
    });
  };

  const partCategoryOptions = [
    { label: "Spare Parts", value: "spare-parts" },
    { label: "Consumable", value: "consumable" },
  ];

  const variationsOptions = [
    { label: "N/A", value: "n/a" },
    { label: "Left Hand", value: "left-hand" },
    { label: "Right Hand", value: "right-hand" },
    { label: "Front", value: "front" },
    { label: "Rear", value: "rear" },
  ];

  const salesUnitOptions = [
    { label: "Pair Unit", value: "pair-unit" },
    { label: "Units", value: "units" },
    { label: "Milliliter", value: "milliliter" },
    { label: "Liter", value: "liter" },
    { label: "Kg", value: "kg" },
  ];

  const purchaseUnitOptions = [
    { label: "Pair Unit", value: "pair-unit" },
    { label: "Units", value: "units" },
    { label: "Milliliter", value: "milliliter" },
    { label: "Liter", value: "liter" },
    { label: "Kg", value: "kg" },
  ];

  const vehicleCategoryOptions = [
    { label: "All", value: "all" },
    { label: "Sedan", value: "sedan" },
    { label: "Van", value: "van" },
    { label: "SUV", value: "suv" },
  ];

  const vehicleMakeOptions = [
    {
      label: 'Japanese Brands',
      options: [
        { label: 'Toyota', value: 'toyota' },
        { label: 'Nissan', value: 'nissan' },
        { label: 'Honda', value: 'honda' },
        { label: 'Suzuki', value: 'suzuki' },
        { label: 'Mitsubishi', value: 'mitsubishi' },
        { label: 'Mazda', value: 'mazda' },
      ],
    },
    {
      label: 'Indian Brands',
      options: [
        { label: 'Tata', value: 'tata' },
        { label: 'Mahindra', value: 'mahindra' },
        { label: 'Maruti Suzuki', value: 'maruti-suzuki' },
      ],
    },
    {
      label: 'Korean Brands',
      options: [
        { label: 'Hyundai', value: 'hyundai' },
        { label: 'Kia', value: 'kia' },
      ],
    },
  ];

  const warrantyPeriodOptions = [
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];

  const disabled =
    singleCustomer &&
    singleCustomer[0].dob === customerValue.dob &&
    singleCustomer[0].phone === customerValue.phone &&
    singleCustomer[0].name === customerValue.name &&
    singleCustomer[0].country === customerValue.country &&
    singleCustomer[0].city === customerValue.city &&
    singleCustomer[0].email === customerValue.email &&
    singleCustomer[0].address === customerValue.address;

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!customerValue["name"]) {
      errorss["name"] = getFormattedMessage(
        "globally.input.name.validate.label"
      );
    } else if (!EmailValidator.validate(customerValue["email"])) {
      if (!customerValue["email"]) {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.validate.label"
        );
      } else {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.valid.validate.label"
        );
      }
    } else if (!customerValue["country"]) {
      errorss["country"] = getFormattedMessage(
        "globally.input.country.validate.label"
      );
    } else if (!customerValue["city"]) {
      errorss["city"] = getFormattedMessage(
        "globally.input.city.validate.label"
      );
    } else if (!customerValue["address"]) {
      errorss["address"] = getFormattedMessage(
        "globally.input.address.validate.label"
      );
    } else if (!customerValue["phone"]) {
      errorss["phone"] = getFormattedMessage(
        "globally.input.phone-number.validate.label"
      );
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handleCallback = (date) => {
    setCustomerValue((previousState) => {
      return { ...previousState, dob: date };
    });
    setErrors("");
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    setCustomerValue((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setErrors("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const valid = handleValidation();
    if (singleCustomer && valid) {
      if (!disabled) {
        setCustomerValue(customerValue);
      }
    } else {
      if (valid) {
        setCustomerValue(customerValue);
        addCustomerData(customerValue);
      }
    }
  };

  return (
    <>
      <div className="form-container">
        <div className="form-header">
          <div className="back">
            <Link to="/admin/services">
              <img src={Icons.arrow_left} alt="Back Icon" />
            </Link>
            <h3>Add New Spare Parts</h3>
          </div>
          <button className="button form-button">
            <span>Upload Bulk</span>
          </button>
        </div>
        <Form className="form" layout="vertical">
          <div className="service-details">
            {/* {error && (
              <div className="main-error">
                <h4>Vendor Account Creation Failed. Please check whether all required fields are filled in correctly</h4>
                <img src={Icons.close_icon} alt="Close Icon" />
              </div>
            )} */}
            <h3 className="form-header">
              Spare Parts Details
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="sku-code" id="sku-code" required />
                <label className="form-label" htmlFor="sku-code">
                  <h4>SKU Code</h4>
                </label>
                {/* {error && (
                  <div className="field-error">
                    <img src={Icons.warning_octagon} alt="Warning Icon" />
                    <span>Please enter First Name</span>
                  </div>
                )} */}
              </div>
              <div className="form-item">
                <RadioSelect
                  options={partCategoryOptions}
                  placeholderText="Select a part category"
                />
                <label className="form-label" htmlFor="part-category">
                  <h4>Part Category</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="part-name" id="part-name" required />
                <label className="form-label" htmlFor="part-name">
                  <h4>Part Name</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="part-number" id="part-number" required />
                <label className="form-label" htmlFor="part-number">
                  <h4>Part Number</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="description" id="description" />
                <label className="form-label" htmlFor="description">
                  <h4>Description</h4>
                </label>
              </div>
              <div className="form-item">
                <RadioSelect
                  options={variationsOptions}
                  placeholderText="Select a variation"
                />
                <label className="form-label" htmlFor="variation">
                  <h4>Variation</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="material" id="material" />
                <label className="form-label" htmlFor="material">
                  <h4>Material</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="group-input-wrapper">
                  <input type="text" name="sales-quanity" id="sales-quanity" />
                  <CustomOptionsSelect
                    options={salesUnitOptions}
                    modalTitle="Add Unit and Quantity of Sales"
                    placeholderText="Enter unit and quantity of sales"
                  />
                </div>
                <label className="form-label" htmlFor="unit">
                  <h4>Unit and Quantity of Sales</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <div className="group-input-wrapper">
                  <input type="text" name="purchase-quanity" id="purchase-quanity" />
                  <CustomOptionsSelect
                    options={purchaseUnitOptions}
                    modalTitle="Add Unit and Quantity of Purchase"
                    placeholderText="Enter unit and quantity of purchase"
                  />
                </div>
                <label className="form-label" htmlFor="unit-purchase">
                  <h4>Unit and Quantity of Purchase</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="min-order-quantity" id="min-order-quantity" />
                <label className="form-label" htmlFor="min-order-quantity">
                  <h4>Minimum Order Quantity - Supplier</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="reorder-level" id="reorder-level" />
                <label className="form-label" htmlFor="reorder-level">
                  <h4>Reorder Level</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="radio-input-wrapper">
                  <div className="radio-wrapper">
                    <input type="radio" name="applicable" id="applicable" value="applicable" required />
                    <label className="radio-label" htmlFor="applicable">
                      Applicable
                    </label>
                  </div>
                  <div className="radio-wrapper">
                    <input type="radio" name="applicable" id="not-applicable" value="not-applicable" required />
                    <label className="radio-label" htmlFor="not-applicable">
                      Not Applicable
                    </label>
                  </div>
                </div>
                <label className="form-label" htmlFor="applicable">
                  <h4>Warranty</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <div className="group-input-wrapper">
                  <input type="text" name="warranty-period" id="warranty-period" />
                  <RadioSelect
                    options={warrantyPeriodOptions}
                  />
                </div>
                <label className="form-label" htmlFor="warranty-period">
                  <h4>Warranty Period</h4>
                </label>
              </div>
              <div className="form-item">
                <CustomMultipleSelect
                  options={vehicleCategoryOptions}
                  dropdownPlaceholderText="Select vehicle category"
                  modalTitle="Add Vehicle Category"
                  modalPlaceholderText="Enter vehicle category"
                />
                <label className="form-label" htmlFor="vehicle-category">
                  <h4>Vehicle Category</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <CustomGroupSelect
                  groupedOptions={vehicleMakeOptions}
                  dropdownPlaceholderText="Select vehicle make"
                  modalTitle="Add a Vehicle Make"
                  selectFieldLabel="Vehicle Make Category"
                  selectPlaceholderText="Select vehicle make category"
                  formFieldLabel="Vehicle Make"
                  formPlaceholderText="Enter vehicle make"
                />
                <label className="form-label" htmlFor="vehicle-make">
                  <h4>Vehicle Make</h4>
                </label>
              </div>
              <div className="form-item">
                <CustomSubGroupSelect />
                <label className="form-label" htmlFor="vehicle-model">
                  <h4>Vehicle Model</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="purchase-price" id="purchase-price" required />
                <label className="form-label" htmlFor="purchase-price">
                  <h4>Purchase Price</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="sale-price" id="sale-price" required />
                <label className="form-label" htmlFor="sale-price">
                  <h4>Sale Price (MRP)</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <UploadComponent
                  text="Click to upload or drag & drop a image here"
                  mobileText="Click to upload a image here"
                  subText="PNG, JPG and JPEG format, up to 5MB"
                  buttonText="Upload Image"
                  inputId="spare-part-image"
                  required={false}
                />
                <label className="form-label" htmlFor="spare-part-image">
                  <h4>Spare Part Image</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="add-notes">
            <div className="form-header sub-header">
              <h3>Add Notes</h3>
              <button className="button form-button">
                <img src={Icons.plus} alt="Edit Icon" />
                <span>Add New Notes</span>
              </button>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <textarea name="note" id="note" />
                <label className="form-label" htmlFor="note">
                  <h4>Note</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <UploadComponent
                  text="Click to Upload or Drag & Drop Your File Here"
                  mobileText="Click to Upload or Drag & Drop Your File Here"
                  subText="DOCX, PNG, JPG and JPEG and PDF format, up to 15MB"
                  buttonText="Upload File"
                  inputId="attachments"
                  required={false}
                />
                <label className="form-label" htmlFor="attachments">
                  <h4>Attachments</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full note-preview">
                <div className="note-container">
                  <div className="note-content">
                    <div className="note-header">
                      <h4>Checked vehicle parts and verified that additional bumper fittings are required.</h4>
                      <img src={Icons.trash} alt="Delete Icon" />
                    </div>
                    <div className="note-item">
                      <img src={Icons.image} alt="Image Icon" />
                      <h4>image.png</h4>
                    </div>
                    <div className="note-item">
                      <img src={Icons.pdf_file} alt="PDF File Icon" />
                      <h4>note.pdf</h4>
                    </div>
                    <div className="note-footer">
                      <h6>Notes by: Super Admin on 8-22-2024 10:31:34</h6>
                    </div>
                  </div>
                </div>
                <label className="form-label">
                  <h4>Note 1</h4>
                </label>
              </div>
            </div>
            <div className="form-row form-footer">
              <button
                type="submit"
                className="button disabled-button"
              >
                <h5>Save Note 1</h5>
              </button>
              <button
                type="submit"
                className="button"
              >
                <h5>Save Note</h5>
              </button>
            </div>
          </div>
          <div className="additional-info">
            <h3 className="info-header">
              Additional Information
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="spare-parts-primary-identifier" id="spare-parts-primary-identifier" />
                <label className="form-label" htmlFor="spare-parts-primary-identifier">
                  <h4>Spare Parts Primary Identifier</h4>
                </label>
              </div>
              <div className="form-item">
                <ReactDatePicker
                  onChangeDate={handleStartDateCallback} id="spare-parts-added-date"
                />
                <label className="form-label" for="spare-parts-added-date">
                  <h4>Spare Parts Added Date</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="spare-parts-added-by" id="spare-parts-added-by" />
                <label className="form-label" htmlFor="spare-parts-added-by">
                  <h4>Spare Parts Added By</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="radio-input-wrapper">
                  <div className="radio-wrapper">
                    <input type="radio" name="stock-status" id="available" value="available" required />
                    <label className="radio-label" htmlFor="available">
                      Available
                    </label>
                  </div>
                  <div className="radio-wrapper">
                    <input type="radio" name="stock-status" id="out-of-stock" value="out-of-stock" required />
                    <label className="radio-label" htmlFor="out-of-stock">
                      Out of Stock
                    </label>
                  </div>
                </div>
                <label className="form-label" htmlFor="stock-status">
                  <h4>Stock Status</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              className="button button-cancel"
              onClick={() => navigate(-1)}
            >
              <h5>Cancel</h5>
            </button>
            <button
              type="submit"
              className="button button-submit"
              onClick={() => setVisible(true)}
            >
              <h5>Add Spare Part</h5>
            </button>
          </div>
        </Form >
      </div >
    </>
  );
};

export default SparePartForm;
