import React from "react";
import { Modal } from "antd";
import DropdownSearch from "./DropdownSearch";

const AddModalSelect = ({ isModalOpen, handleModalClose, options, modalTitle, selectFieldLabel, selectPlaceholderText, formFieldLabel, formPlaceholderText }) => (
    <Modal
        open={isModalOpen}
        onCancel={handleModalClose}
        footer={null}
        centered
        width={350}
        title={<h3>{modalTitle}</h3>}
        className="add-modal"
    >
        <div className="add-vehicle-select">
            <h5 className="add-modal-selection-label">{selectFieldLabel}</h5>
            <DropdownSearch />
        </div>
        <div className="add-vehicle-form">
            <h5 className="add-modal-selection-label">{formFieldLabel}</h5>
            <input type="text" placeholder={formPlaceholderText} className="add-vehicle-input" />
            <div className="add-vehicle-form-buttons">
                <button className="button add-vehicle-submit">
                    <h5>Submit</h5>
                </button>
            </div>
        </div>
    </Modal>
);

export default AddModalSelect;