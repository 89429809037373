import React, { useCallback, useEffect, useState } from 'react';
import { getFormattedMessage } from '../sharedMethod';
import { Modal } from "antd";
import Icons from "../../utils/icons";

const DeleteModel = (props) => {
    const { onClickDeleteModel, deleteUserClick, name } = props;
    const [visible, setVisible] = useState(true);

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            // User for Close the model on Escape
            onClickDeleteModel(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);
        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, []);

    return (
        <Modal
            centered
            open={visible}
            onOk={deleteUserClick}
            onCancel={onClickDeleteModel}
            className="delete-modal"
            footer={[
                <button key="submit" onClick={deleteUserClick} className="modal-btn modal-btn__delete">
                    <h5>{getFormattedMessage('delete-modal.yes-btn')}</h5>
                </button>,
                <button key="back" onClick={onClickDeleteModel} className="modal-btn modal-btn__cancel">
                    <h5>{getFormattedMessage('delete-modal.no-btn')}</h5>
                </button>
            ]}
        >
            <div className="delete-modal__body">
                <img src={Icons.delete_warning} alt="delete-warning" />
                <h3>{getFormattedMessage('delete-modal.title')}</h3>
                <div className="delete-modal__message">
                    <span>{getFormattedMessage('delete-modal.msg')}</span>
                    <h6>{getFormattedMessage('delete-modal.undone-msg')}</h6>
                </div>
            </div>
        </Modal>
    )
};
export default DeleteModel;
