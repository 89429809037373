import { combineReducers } from '@reduxjs/toolkit';
import jobReducer from './slices/jobSlice';
import toastReducer from './slices/toastSlice';
import profileReducer from './slices/profileSlice';
import vendorSlice from './slices/vendorSlice';
import sparePartSlice from './slices/sparePartSlice';
import serviceSlice from './slices/serviceSlice';
import rateCardSlice from './slices/rateCardSlice';
import mobileCodesSlice from './slices/mobileCodesSlice';
import locationDataSlice from './slices/locationDataSlice';
import taxonomySlice from './slices/taxonomySlice';

const rootReducer = combineReducers({
    jobs: jobReducer,
    toasts: toastReducer,
    profile: profileReducer,
    vendors: vendorSlice,
    spareparts: sparePartSlice,
    services: serviceSlice,
    rateCards: rateCardSlice,
    mobileCodes: mobileCodesSlice,
    locations: locationDataSlice,
    taxonomy: taxonomySlice,
});

export default rootReducer;
