import Dashboard from "./components/dashboard/Dashboard";
import Profile from "./components/profile/Profile";
import ProfileEdit from "./components/profile/ProfileEdit";

export const route = [
    {
        path: "/dashboard",
        ele: <Dashboard />,
        permission: "", 
    },
    {
        path: "/my-profile",
        ele: <Profile />,
        permission: "", 
    },
    {
        path: "/edit-profile",
        ele: <ProfileEdit />,
        permission: "", 
    },
];
