import { Tokens } from '../constants';
import { environment } from './environment';
import { refreshTokenAction } from '../store/action/authAction';

export default {
    setupInterceptors: (axios, dispatch) => {
        axios.interceptors.request.use(
            (config) => {
                //let isToken = localStorage.getItem(Tokens.VENDOR);
                let isToken = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJwaXJhc2FudGhAY29kZWxhbnRpYy5jb20iLCJpYXQiOjE3MzM0NzY5MDQsImV4cCI6MTczMzUxMjkwNCwidXNlcl90eXBlIjoiU1VQRVJBRE1JTiIsInVzZXJfaWQiOiI5NWJiZTA2OS01MmJlLTRkZjAtOTQyNS1lMjFiMTc0YjQ1ZjYifQ.WpN0ajOUIhBzAkQilKLGVK8Ba5FoZYlzCt_tEtjqV5v_d6M0_xgQvbVY1Tkx-LqiqPlTlU_8L9Rq8ouDKgqZgQ";
                /*if (isToken) {
                    config.headers['Authorization'] = `Bearer ${isToken}`;
                } else {
                    if (!window.location.href.includes('login') && 
                        !window.location.href.includes('reset-password') && 
                        !window.location.href.includes('forgot-password')) {
                        window.location.href = environment.URL + '#/' + 'login';
                    }
                }*/
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => successHandler(response),
            async (error) => {
                const originalRequest = error.config;

                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true; // Prevent infinite loops

                    // Call your refresh token action
                    const refreshResponse = await dispatch(refreshTokenAction());

                    if (refreshResponse && refreshResponse.error) {
                        // Handle token refresh error, e.g., redirect to login
                        window.location.href = environment.URL + '#/' + 'login';
                        return Promise.reject(error);
                    }

                    // If the token refresh is successful, retry the original request
                    const newAccessToken = localStorage.getItem(Tokens.VENDOR);
                    axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    return axios(originalRequest);
                }

                return Promise.reject(error);
            }
        );
    }
};

const successHandler = (response) => {
    return response;
};
