import React, { useState } from "react";
import Select from "react-select";
import { Button } from "antd";
import Icons from "../../utils/icons";

const RadioSelect = ({ options, placeholderText }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleOpen = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const onSelectChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    toggleOpen();
  };

  const CustomOption = (props) => {
    const { data, isSelected, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`radio-option ${isSelected ? "radio-option__selected" : ""}`}
      >
        <input
          type="radio"
          checked={isSelected}
          readOnly
        />
        {data.label}
      </div>
    );
  };

  return (
    <div className="dropdown radio-option-dropdown">
      <Button
        onClick={toggleOpen}
        icon={<img src={Icons.caret_black_down} alt="chevron-down" className={isDropdownOpen ? "rotate" : ""} />}
        iconPosition="end"
        className={`dropdown-button ${isDropdownOpen ? "opened" : ""} ${selectedOption ? "selected" : ""}`}
      >
        {selectedOption ? `${selectedOption.label}` : placeholderText || ' '}
      </Button>
      {isDropdownOpen && (
        <>
          <div className="dropdown-menu">
            <Select
              components={{ Option: CustomOption }}
              options={options}
              value={selectedOption}
              onChange={onSelectChange}
              menuIsOpen={true}
              isSearchable={false}
              tabSelectsValue={false}
              hideSelectedOptions={false}
              controlShouldRenderValue={false}
            />
          </div>
          <div
            onClick={toggleOpen}
            className="dropdown-blanket"
          />
        </>
      )}
    </div>
  );
};

export default RadioSelect;
