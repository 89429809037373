import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { Menu } from "antd";
import karstationLogoWhite from '../../assets/images/karstation-logo-white.svg';
import Icons from "../../utils/icons"
import { logoutAction } from '../../store/action/authAction';
import { Tokens } from '../../constants/index'

const Sidebar = (props) => {
  const { logoutAction, collapsed, setCollapsed, toggleSider } = props;
  const token = localStorage.getItem(Tokens.VENDOR);
  const [openKeys, setOpenKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleCollapseClick = () => {
    setCollapsed(!collapsed);
    if (window.innerWidth < 767) {
      toggleSider();
    }
  };

  const getActiveKey = () => {
    if (location.pathname.includes('/admin/dashboard')) return 'dashboard';
    if (location.pathname.includes('/admin/my-profile')) return 'profile';
    if (location.pathname.includes('/admin/vendors')) return 'vendors';
    if (location.pathname.includes('/admin/spare-parts')) return 'spare-parts';
    if (location.pathname.includes('/admin/services')) return 'services';
    if (location.pathname.includes('/admin/rate-cards')) return 'rate-cards';
    return '';
  };

  useEffect(() => {
    if (location.pathname.includes('/admin/spare-parts') || location.pathname.includes('/admin/services') || location.pathname.includes('/admin/rate-card')) {
      setOpenKeys(['spare-parts-and-services']);
    }
  }, [location.pathname]);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const onLogOut = () => {
    logoutAction(token, navigate);
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div className={`logo-container ${collapsed ? 'collapsed-logo-container' : ''}`}>
        {!collapsed ? <img src={karstationLogoWhite} alt='karstation logo' className='logo' /> : null}
        <div className='collapse-icon' onClick={handleCollapseClick}>
          <img src={collapsed ? Icons.menu_unfold : Icons.menu_fold} alt='collapse icon' />
        </div>
      </div>
      <div className="scrollable-content">
        <div className={`menu-container top-menu ${collapsed ? 'collapsed-menu' : ''}`}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[getActiveKey()]}
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            onClick={({ key }) => {
              if (key === "dashboard") {
                navigate('/admin/dashboard')
              } else if (key === "vendors") {
                navigate('/admin/vendors')
              } else if (key === "spare-parts") {
                navigate('/admin/spare-parts')
              } else if (key === "services") {
                navigate('/admin/services')
              } else if (key === "rate-cards") {
                navigate('/admin/rate-cards')
              }
            }}
            items={[
              {
                key: "dashboard",
                icon: (
                  <img
                    src={Icons.dashboard_icon}
                    alt="Dashboard Icon"
                  />
                ),
                label: "Dashboard",
              },
              {
                key: "vendors",
                icon: (
                  <img
                    src={Icons.users_three}
                    alt="Vendor Icon"
                  />
                ),
                label: "Vendor Management",
              },
              {
                key: "spare-parts-and-services",
                icon: (
                  <img
                    src={Icons.progress_wrench}
                    alt="Spare Part Icon"
                  />
                ),
                label: (
                  <>
                    Spare Parts & Service
                    <br />
                    Management
                  </>
                ),
                children: [
                  {
                    key: "spare-parts",
                    label: "Spare Parts",
                  },
                  {
                    key: "services",
                    label: "Services",
                  },
                  {
                    key: "rate-cards",
                    label: "Rate Card",
                  },
                ],
                expandIcon: ({ isOpen }) =>
                  <img src={Icons.caret_right_white} alt="caret" className={`sub-menu-caret ${isOpen ? 'open' : ''}`} />,
              },
            ]}
          />
        </div>
        <div className={`menu-container bottom-menu ${collapsed ? 'collapsed-menu' : ''}`}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[getActiveKey()]}
            onClick={({ key }) => {
              if (key === "profile") {
                navigate('/app/my-profile')
              }
            }}
            items={[
              {
                key: "profile",
                icon: (
                  <img
                    src={Icons.profile_icon}
                    alt="Profile Icon"
                  />
                ),
                label: 'Profile Settings',
              },
              {
                key: "logout",
                onClick: onLogOut,
                icon: (
                  <img
                    src={Icons.signout_icon}
                    alt="Signout Icon"
                  />
                ),
                label: "Logout",
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(null, { logoutAction })(Sidebar);