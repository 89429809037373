import React from 'react';
import { getFormattedMessage } from '../../shared/sharedMethod';
import Spinner from "../../shared/components/loaders/Spinner";
import Icons from '../../utils/icons';

const EmptyComponent = (props) => {
    const { isLoading, noDataText } = props;

    return (
        <>
            {isLoading ? <Spinner /> :
                <div className='no-data-container'>
                    <div className="no-data-icon"><img src={Icons.warning_octagon}></img></div>
                    <h2>{getFormattedMessage('vendors.no-record-found.title')}</h2>
                    <p>{noDataText}</p>
                </div>
            }
        </>
    );
};

export default EmptyComponent;
