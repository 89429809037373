import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form } from "antd";
import * as EmailValidator from "email-validator";
import {
  getFormattedMessage,
  placeholderText,
  numValidate
} from "../../../shared/sharedMethod";
import ReactDatePicker from "../../../shared/datepicker/ReactDatePicker";
import Icons from "../../../utils/icons";
import ImageUpload from "./ImageUpload";
import DropdownSearch from "../../../shared/select/DropdownSearch";
import MultipleSelect from "../../../shared/select/MultipleSelect";
import TelephoneInput from "../../../shared/phoneInput/TelephoneInput";
import CreateSuccessModal from "./CreateSuccessModal";

const VendorForm = (props) => {
  const { addCustomerData, id } = props;
  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [errors, setErrors] = useState({
    dob: "",
    name: "",
    email: "",
    phone: "",
    country: "",
    city: "",
    address: "",
  });

  const handleStartDateCallback = ( date ) => {
    setStartDate((previousState) => {
        return { ...previousState, start_date: date };
    });
  };

  const businessTypesOptions = [
      { name: "Service Station", id: 1 },
      { name: "Mechanical Repair", id: 2 },
      { name: "Accidental Repair", id: 3 },
      { name: "Spare Parts", id: 4 }
  ];

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!customerValue["name"]) {
      errorss["name"] = getFormattedMessage(
        "globally.input.name.validate.label"
      );
    } else if (!EmailValidator.validate(customerValue["email"])) {
      if (!customerValue["email"]) {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.validate.label"
        );
      } else {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.valid.validate.label"
        );
      }
    } else if (!customerValue["country"]) {
      errorss["country"] = getFormattedMessage(
        "globally.input.country.validate.label"
      );
    } else if (!customerValue["city"]) {
      errorss["city"] = getFormattedMessage(
        "globally.input.city.validate.label"
      );
    } else if (!customerValue["address"]) {
      errorss["address"] = getFormattedMessage(
        "globally.input.address.validate.label"
      );
    } else if (!customerValue["phone"]) {
      errorss["phone"] = getFormattedMessage(
        "globally.input.phone-number.validate.label"
      );
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const handleCallback = (date) => {
    setCustomerValue((previousState) => {
      return { ...previousState, dob: date };
    });
    setErrors("");
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    setCustomerValue((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setErrors("");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const valid = handleValidation();

  };

  return (
    <>
      <div className="form-container">
        <div className="form-header">
          <div className="back">
            <Link to="/admin/vendors">
              <img src={Icons.arrow_left} alt="Back Icon" />
            </Link>
            <h3>Add New Vendor</h3>
          </div>
        </div>
        <Form className="form" layout="vertical">
          <div className="personal-info">
            {error && (
              <div className="main-error">
                <h4>Vendor Account Creation Failed. Please check whether all required fields are filled in correctly</h4>
                <img src={Icons.close_icon} alt="Close Icon" />
              </div>
            )}
            <h3 className="info-header">
              Personal Information
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="first-name" id="first-name" required />
                <label className="form-label" htmlFor="first-name">
                  <h4>First Name</h4>
                </label>
                {error && (
                  <div className="field-error">
                    <img src={Icons.warning_circle} alt="Warning Circle" />
                    <h6>Please enter First Name</h6>
                  </div>
                )}
              </div>
              <div className="form-item">
                <input type="text" name="last-name" id="last-name" required />
                <label className="form-label" htmlFor="last-name">
                  <h4>Last Name</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <div className="input-wrapper">
                  <TelephoneInput />
                </div>
                <label className="form-label" htmlFor="mobile-number">
                  <h4>Mobile Number</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="email" name="email" id="email" required />
                <label className="form-label" htmlFor="email">
                  <h4>Email Address</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="nic" id="nic" required />
                <label className="form-label" htmlFor="nic">
                  <h4>NIC</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <ImageUpload
                  title="Upload NIC Front"
                  text="Click to upload or drag & drop a copy of"
                  highlightedText="valid NIC"
                  subText="PNG, JPG and JPEG format, up to 3MB"
                  buttonText="Upload"
                  inputId="nic-front"
                  required={true}
                />
                <label className="form-label" htmlFor="nic-front">
                  <h4>Upload NIC</h4>
                </label>
              </div>
              <div className="form-item">
                <ImageUpload
                  title="Upload NIC Back"
                  text="Click to upload or drag & drop a copy of"
                  highlightedText="valid NIC"
                  subText="PNG, JPG and JPEG format, up to 3MB"
                  buttonText="Upload"
                  inputId="nic-back"
                  required={true}
                />
                <label className="form-label" htmlFor="nic-back" style={{ visibility: 'hidden' }}>
                  <h4>Upload NIC</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <ImageUpload
                  text="Click to upload or drag & drop a profile photo"
                  subText="PNG, JPG and JPEG format, up to 3MB"
                  buttonText="Upload Profile Photo"
                  inputId="profile-photo"
                  required={false}
                />
                <label className="form-label" htmlFor="profile-photo">
                  <h4>Profile Photo</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="business-info">
            <h3 className="info-header">
              Business Information
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="business-name" id="business-name" required />
                <label className="form-label" htmlFor="business-name">
                  <h4>Business Name</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="input-wrapper">
                  <TelephoneInput />
                </div>
                <label className="form-label" htmlFor="telephone-number">
                  <h4>Telephone Number</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="address-line-1" id="address-line-1" required />
                <label className="form-label" htmlFor="address-line-1">
                  <h4>Business Address Line 1</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="address-line-2" id="address-line-2" />
                <label className="form-label" htmlFor="address-line-2">
                  <h4>Business Address Line 2</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="city" id="city" required />
                <label className="form-label" htmlFor="city">
                  <h4>City/Town</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="postal-code" id="postal-code" required />
                <label className="form-label" htmlFor="postal-code">
                  <h4>Postal Code</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <DropdownSearch />
                <label className="form-label" htmlFor="district">
                  <h4>District</h4>
                </label>
              </div>
              <div className="form-item">
                <DropdownSearch />
                <label className="form-label" htmlFor="province">
                  <h4>Province</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="country" id="country" required />
                <label className="form-label" htmlFor="country">
                  <h4>Country</h4>
                </label>
              </div>
              <div className="form-item">
                <input type="text" name="registration-no" id="registration-no" />
                <label className="form-label" htmlFor="registration-no">
                  <h4>Registration No</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <ImageUpload
                  text="Click to upload or drag & drop a copy of"
                  highlightedText="valid business registration"
                  subText="PDF format, up to 20MB"
                  buttonText="Upload Copy of Business Registration"
                  inputId="reg-doc"
                  required={false}
                />
                <label className="form-label" htmlFor="registration-document">
                  <h4>Upload Registration Document</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="email" name="business-email" id="business-email" required />
                <label className="form-label" htmlFor="business-email">
                  <h4>Business Email Address</h4>
                </label>
              </div>
              <div className="form-item">
                <MultipleSelect 
                    placeholder="Select Business Type"
                    options={businessTypesOptions}
                    listStyle={{ 
                        marginTop: '10px', 
                        zIndex: 2, 
                        position: 'absolute', 
                        backgroundColor: '#fff', 
                        padding: '10px', 
                        width: '100%' 
                    }}
                    maxTagsToShow='1'
                />
                <label className="form-label" htmlFor="business-type">
                  <h4>Business Type</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="website-url" id="website-url" />
                <label className="form-label" htmlFor="website-url">
                  <h4>Website URL</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item form-item__full">
                <ImageUpload
                  text="Click to upload or drag & drop a business logo"
                  subText="PNG, JPG and JPEG format, up to 3MB"
                  buttonText="Upload Business Logo"
                  inputId="business-logo"
                  required={false}
                />
                <label className="form-label" htmlFor="business-logo">
                  <h4>Business Logo</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="additional-info">
            <h3 className="info-header">
              Additional Information
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="vendor-id" id="vendor-id" required />
                <label className="form-label" htmlFor="vendor-id">
                  <h4>Vendor ID</h4>
                </label>
              </div>
              <div className="form-item">
                <ReactDatePicker onChangeDate={handleStartDateCallback} id="joined-date"/> 
                <label className="form-label" htmlFor="joined-date">
                  <h4>Joined Date</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="registered-by" id="registered-by" required />
                <label className="form-label" htmlFor="registered-by">
                  <h4>Registered By</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="input-wrapper input-wrapper__radio">
                  <div className="radio-wrapper">
                    <input type="radio" name="status" id="active" value="active" required />
                    <label className="radio-label" htmlFor="active">
                      Active
                    </label>
                  </div>
                  <div className="radio-wrapper">
                    <input type="radio" name="status" id="inactive" value="inactive" required />
                    <label className="radio-label" htmlFor="inactive">
                      Inactive
                    </label>
                  </div>
                </div>
                <label className="form-label" htmlFor="status">
                  <h4>Status</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="account-credentials">
            <h3 className="info-header">
              Account Credentials
            </h3>
            <div className="form-row">
              <div className="form-item">
                <input type="text" name="username" id="username" required />
                <label className="form-label" htmlFor="username">
                  <h4>Username</h4>
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-item">
                <div className="password-wrapper">
                  <input type={passwordVisible ? "text" : "password"} name="password" id="password" required />
                  <span className="password-icon">
                    <img
                        src={passwordVisible ? Icons.eye_closed : Icons.eye_closed}
                        alt="Toggle password visibility"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                    />
                  </span>
                </div>
                <label className="form-label" htmlFor="password">
                  <h4>Password</h4>
                </label>
              </div>
              <div className="form-item">
                <div className="password-wrapper">
                  <input type={confirmPasswordVisible ? "text" : "password"} name="confirm-password" id="confirm-password" required />
                  <span className="password-icon">
                    <img
                        src={confirmPasswordVisible ? Icons.eye_closed : Icons.eye_closed}
                        alt="Toggle password visibility"
                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                    />
                  </span>
                </div>
                <label className="form-label" htmlFor="confirm-password">
                  <h4>Confirm Password</h4>
                </label>
              </div>
            </div>
          </div>
          <div className="form-footer">
            <button
              type="button"
              className="button button-cancel"
              onClick={() => navigate(-1)}
            >
              <h5>Cancel</h5>
            </button>
            <button
              type="submit"
              className="button button-submit"
              onClick={() => setVisible(true)}
            >
              <h5>Register Vendor/Garage</h5>
            </button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default VendorForm;
