import React, { useState, useEffect} from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import MasterLayout from "../../MasterLayout";
import ReactDataTable from "../../../shared/table/ReactDataTable";
import DeleteVendor from "./DeleteVendor";
import TabTitle from "../../../shared/tab-title/TabTitle";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllVendors } from '../../../slices/vendorSlice';
import { Tooltip } from 'antd';
import { Filters } from "../../../constants";
import {
    getFormattedMessage,
    placeholderText,
} from "../../../shared/sharedMethod";
import TopProgressBar from "../../../shared/components/loaders/TopProgressBar";
import Breadcrumb from "../../../shared/breadcrumb/Breadcrumb";
import ActionButton from "../../../shared/action-buttons/ActionButton";
import Spinner from "../../../shared/components/loaders/Spinner";

const Vendors = () => {
    const { isVendorsLoading, allVendors, totalRecord, totalPages, error } = useSelector((state) => state.vendors);
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const [filter, setFilter] = useState({ 
        page: Filters.OBJ.page, 
        size: Filters.OBJ.size, 
        searchTerm: '', 
        status: '', 
        businessType: '', 
        toDate: '', 
        fromDate: '' 
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllVendors(filter));
    }, [dispatch, filter]);
  
    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };

    const onChange = (filters) => {
        setFilter((prevFilter) => {
            const newFilter = { ...prevFilter, ...filters };
            if (JSON.stringify(prevFilter) !== JSON.stringify(newFilter)) {
                return newFilter;
            }
            return prevFilter;
        });
    };    

    const goToEditVendor = (item) => {
        const id = item.id;
        navigate(`/admin/vendors/edit/${id}`);
    };

    const goToVendorDetailPage = (id) => {
        window.location.href = "#/admin/vendors/detail/" + id;
    };

    const onExcelClick = () => {
        
    };

    const itemsValue =
    allVendors.length >= 0 &&
    allVendors.map((vendor, index) => ({
        id: vendor.vendorPrimaryId, 
        no: (filter.page - 1) * filter.size + index + 1,
        vendor_id: vendor.vendorId,
        first_name: vendor.firstName,
        last_name: vendor.lastName,
        mobile: vendor.mobileNumber,
        email: vendor.emailAddress,
        nic: vendor.nic,
        business_name: vendor.businessName,
        business_address_line1: vendor.businessAddressLine1,
        district: vendor.district,
        province: vendor.province,
        telephone: vendor.businessTelephone,
        business_email: vendor.businessEmail,
        business_type: vendor.businessTypeSaveRequestDtoList.map(type => type.businessRateTypeName || type.businessPrimaryRateTypeId),
        joined_date: vendor.joinDate,
        status: vendor.status ? "Active" : "Inactive",
        registered_by: vendor.registeredBy
    }));

    const columns = [
        {
            id: 1,
            name: "No",
            selector: (row) => row.no,            
            sortField: "no",
            sortable: true,
            maxWidth: '100px',
            minWidth: '70px',
        },
        {
            id: 2,
            name: "Vendor ID",
            selector: (row) => row.vendor_id,
            sortField: "vendor_id",
            sortable: false,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 3,
            name: "First Name",
            selector: (row) => row.first_name,
            sortField: "first_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 4,
            name: "Last Name",
            selector: (row) => row.last_name,
            sortField: "last_name",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 5,
            name: "Mobile",
            selector: (row) => row.mobile,
            sortField: "mobile",
            sortable: false,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
            grow: 3
        },
        {
            id: 6,
            name: "Email Address",
            selector: (row) => row.email,
            sortField: "email",
            sortable: false,
            wrap: true,
            maxWidth: '600px',
            minWidth: '300px',
            grow: 3
        },
        {
            id: 7,
            name: "NIC",
            selector: (row) => row.nic,
            sortField: "nic",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 8,
            name: "Business Name",
            selector: (row) => row.business_name,
            sortField: "business_name",
            sortable: true,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 9,
            name: "Business Address Line 1",
            selector: (row) => row.business_address_line1,
            sortField: "businessAddressLine1",
            sortable: false,
            maxWidth: '600px',
            minWidth: '200px',
            wrap: true,
            grow: 3
        },
        {
            id: 10,
            name: "District",
            selector: (row) => row.district,
            sortField: "district",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 11,
            name: "Province",
            selector: (row) => row.province,
            sortField: "province",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 12,
            name: "Telephone",
            selector: (row) => row.telephone,
            sortField: "telephone",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '150px',
            wrap: true,
        },
        {
            id: 13,
            name: "Business Email",
            selector: (row) => row.business_email,
            sortField: "business_email",
            sortable: false,
            grow: 3,
            maxWidth: '600px',
            minWidth: '300px',
            wrap: true,
        },
        {
            id: 14,
            name: "Business Type",
            selector: (row) => {
                const businessTypes = row.business_type;
                const visibleTypes = businessTypes.slice(0, 2); 
                const remainingTypes = businessTypes.slice(2); 
        
                return (
                    <div className="business-type-badge-container">
                        {visibleTypes.map((type, index) => (
                            <span key={index} className="business-type-badge">
                                {type}
                            </span>
                        ))}
                        {remainingTypes.length > 0 && (
                            <Tooltip 
                                className="custom-tooltip" 
                                color="#E0EBFF"
                                title={
                                    <div className="tooltip-content">
                                        {remainingTypes.map((type, index) => (
                                            <span key={index} className="badge">
                                                {type}
                                            </span>
                                        ))}
                                    </div>
                                }
                            >
                                <span className="business-type-badge more-badge">
                                    +{remainingTypes.length}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
            sortable: false,
            maxWidth: '600px',
            minWidth: '300px',
            wrap: true,
        },        
        {
            id: 15,
            name: "Joined Date",
            selector: (row) => moment(row.joined_date).format("DD-MM-YYYY"),
            wrap: true,
            sortField: "Joined Date",
            sortable: true,
            maxWidth: '300px',
            minWidth: '150px',
        },
        {
            id: 16,
            name: "Status",
            selector: (row) => row.status,
            sortField: "status",
            grow: 3,
            wrap: true,
            maxWidth: '200px',
            minWidth: '80px',
            sortable: false,
            cell: (row) => (
                <span className={`status-badge ${row.status.toLowerCase()}`}>
                    {row.status}
                </span>
            ),
        },      
        {
            id: 17,
            name: "Registered By",
            selector: (row) => row.registered_by,
            sortField: "registered_by",
            sortable: false,
            wrap: true,
            maxWidth: '400px',
            minWidth: '200px',
        },
        {
            id: 18,
            name: "",
            selector: (row) => null, 
            sortable: false,
            maxWidth: '400px',
            minWidth: '130px',
            cell: (row) => (
                <div className="actions" >
                    <ActionButton
                        isViewIcon={true}
                        goToDetailScreen={goToVendorDetailPage}
                        item={row}
                        goToEditData={goToEditVendor}
                        isEditMode={true}
                        onClickDeleteModel={onClickDeleteModel}
                    />
                </div>
            ),
        },
    ];

    return (
        <MasterLayout>
            <Breadcrumb 
                parent_menu="Vendor Management"
                parent_menu_url="#/admin/vendors"
                current_page="Vendors" 
                current_page_url="" 
            />
            <TopProgressBar />
            <TabTitle title={placeholderText("vendors.title")} />
            {isVendorsLoading ? <Spinner /> : <>
                <ReactDataTable
                    columns={columns}
                    tableTitle={getFormattedMessage("vendors.title")}
                    countTextSingular={getFormattedMessage("vendors.count.title.singular")}
                    countTextPlural={getFormattedMessage("vendors.count.title.plural")}
                    filterTitle={getFormattedMessage("vendors.filter.title")}
                    items={itemsValue}
                    onChange={onChange}
                    currentPage={filter.page}
                    pageSize={filter.size}
                    isLoading={isVendorsLoading}
                    isShowFilterField
                    isShowSearch={true}
                    isStartDateFilter
                    isEndDateFilter
                    isStatusFilter
                    isBusinessTypeFilter
                    ButtonValue={getFormattedMessage("vendors.create.button.title")}
                    totalRows={totalRecord}
                    totalPages={totalPages}
                    noDataText={getFormattedMessage("vendors.no-record-found.text")}
                    isExport ={true}
                    onExcelClick={onExcelClick}
                    to="#/admin/vendors/create"
                />
            </>
            }
            <DeleteVendor
                onClickDeleteModel={onClickDeleteModel}
                deleteModel={deleteModel}
                onDelete={isDelete}
            />
        </MasterLayout>
    );
};

export default Vendors;