import React, { useEffect, useState } from 'react'
import TabTitle from '../../shared/tab-title/TabTitle';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from 'email-validator';
import { forgotPassword } from '../../store/action/authAction';
import { getFormattedMessage, placeholderText } from '../../shared/sharedMethod';
import lock_key_icon from '../../assets/images/lock-key.svg';
import error_email from '../../assets/images/error-email.svg';
import AuthHeader from '../header/AuthHeader';
import white_logo from '../../assets/images/karstation-logo-white.svg';
import error_password from '../../assets/images/error-password.svg';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { loginUser, frontSetting } = useSelector(state => state)
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const [forgotValue, setForgotValue] = useState({ email: '' });
  const [errors, setErrors] = useState({ email: '' });
  const [loading, setLoading] = useState(false);
  const [errorIcon, setErrorIcon] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [error, setError] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    clearEmail();
    if (!loginUser.errorMessage) {
      if (loginUser) {
        setLoading(false);
        setForgotValue({ email: '' })
      }
    } else {
      setLoading(false);
    }
  }, [loginUser, forgotPassword]);

  const handleChange = (e) => {
    e.persist();
    setForgotValue(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    setDisable(false);
    setErrors('');
  };

  const closePopup = () => {
    setShowPopup(false);
    setError("");
  };

  const prepareFormData = () => {
    const formData = new FormData();
    formData.append('email', forgotValue.email);
    return formData;
  };

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if (!EmailValidator.validate(forgotValue['email'])) {
      if (!forgotValue['email']) {
        errorss['email'] = getFormattedMessage('globally.input.email.validate.label');
      } else {
        errorss['email'] = getFormattedMessage('globally.input.email.valid.validate.label');
      }
    } else {
      isValid = true;
    }
    setErrors(errorss);
    return isValid;
  };

  const clearEmail = () => {
    if (loginUser && loginUser === 'We have emailed your password reset link!') {
      setForgotValue({ email: '' });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const valid = handleValidation();
    !valid && setLoading(false);
    if (valid) {
      setLoading(true);
      try {
        const response = await dispatch(
          forgotPassword(forgotValue.email, setLoading)
        );
        if (response && response.error) {
          setErrorIcon(error_email);
          setErrorTitle("Failed");
          setError(response.error);
          setShowPopup(true);
        } else if (response && response.success) {
          setErrorIcon(error_email);
          setErrorTitle("Success");
          setError(response.success);
          setShowPopup(true);
        }
      } catch (error) {
        setErrorIcon(error_password);
        setErrorTitle("Unexpected Error");
        setError("An unexpected error occurred. Please try again.");
        setShowPopup(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className="container">
        <TabTitle title='Forgot Password' />
        <AuthHeader isLoginPage={false} />
        <div className="right-side">
          <img
            src={white_logo}
            alt="KarStation"
            className="logo hide-desktop"
          />
          <div className="form-container forgot-password">
            <img
              src={lock_key_icon}
              alt="KarStation"
              className="login-icon"
            />
            <h1>
              {getFormattedMessage('forgot-password-form.title')}
            </h1>
            <p>
              Provide your account email for which you want to reset your password
            </p>
            <form>
              <div className="mb-sm-7 mb-8">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex justify-content-between w-100">
                    <label className="form-label">
                      {getFormattedMessage(
                        "globally.input.email.label"
                      )}{" "}
                    </label>
                  </div>
                </div>
                <input
                  placeholder={placeholderText(
                    "globally.input.email.placeholder.label"
                  )}
                  required
                  value={forgotValue.email}
                  type='text'
                  name='email'
                  autoComplete='on'
                  onChange={(e) => handleChange(e)}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2 text-start">
                  {errors["email"] ? errors["email"] : null}
                </span>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  onClick={(e) => onSubmit(e)}
                >
                  {loading ? (
                    <span className="d-block">
                      {getFormattedMessage(
                        "globally.loading.label"
                      )}
                    </span>
                  ) : (
                    <span>
                      {getFormattedMessage('forgot-password-form.reset-link-btn.label')}
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="overlay"></div>
          <div className="error-popup">
            <div className="error-popup-content">
              <span className="close-icon" onClick={closePopup}></span>
              <img src={errorIcon} alt="error" className="error-icon" />
              <h2>{errorTitle}</h2>
              <p>{error}</p>
              <button onClick={closePopup}>Retry</button>
            </div>
          </div>
        </>
      )}
    </>
  )
};

export default ForgotPassword;

