import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icons from "../../utils/icons";

const ReactDatePicker = (props) => {
    const { onChangeDate, newStartDate, readOnlyref } = props;
    const [startDate, setStartDate] = useState(new Date());

    const handleCallback = (date) => {
        setStartDate(date);
        onChangeDate(date);
    };

    useEffect(() => {
        setStartDate(startDate);
    }, [startDate]);

    const onDatepickerRef = (el, readOnlyref) => {
        if (el && el.input) {
            el.input.readOnly = readOnlyref !== undefined ? readOnlyref : true;
        }
    };

    return (
        <div className="position-relative datepicker p-0">
            <DatePicker
                wrapperClassName="w-100"
                className="datepicker__custom-datepicker px-4"
                name="date"
                selected={
                    newStartDate === null
                        ? new Date()
                        : newStartDate
                        ? newStartDate
                        : startDate
                }
                dateFormat="dd-MM-yyyy"
                onChange={(date) => handleCallback(date)}
                maxDate={new Date()}
                ref={(el) => onDatepickerRef(el, readOnlyref)}
            />
            <img src={Icons.calendar} alt="Calendar Icon" className="input-icon" />
        </div>
    );
};

export default ReactDatePicker;
