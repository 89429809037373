import React, { useState, useEffect } from "react";
import MasterLayout from "../MasterLayout";
import Icons from "../../utils/icons";
import profile_avatar from "../../assets/images/profile-avatar.png";
import EmptyRecntlyJoined from "./EmptyRecentlyJoined";

const RecentlyJoinedVendors = (props) => {
  return (
    <div className="recently-joined-vendors">
      <div className="recent-vendors-container">
        <div className="recent-vendors-header">
          <h4>Recent Vendors</h4>
          <img src={Icons.squar_out} alt="Refresh" />
        </div>
        <div className="vendor-list">
          <div className="vendor-item">
            <img
              src={profile_avatar}
              alt="Vendor Avatar"
              className="vendor-avatar"
            />
            <div className="vendor-details">
              <h5 className="vendor-name">Jane Doe</h5>
              <p className="vendor-email">janedoe@gmail.com</p>
            </div>
            <div className="vendor-joined">
              <p>Joined at: </p>
              <span>Oct 21, 2024</span>
            </div>
          </div>

          <div className="vendor-item">
            <img
              src={profile_avatar}
              alt="Vendor Avatar"
              className="vendor-avatar"
            />
            <div className="vendor-details">
              <h5 className="vendor-name">Jane Doe</h5>
              <p className="vendor-email">janedoe@gmail.com</p>
            </div>
            <div className="vendor-joined">
              <p>Joined at: </p>
              <span>Oct 21, 2024</span>
            </div>
          </div>
          <div className="vendor-item">
            <img
              src={profile_avatar}
              alt="Vendor Avatar"
              className="vendor-avatar"
            />
            <div className="vendor-details">
              <h5 className="vendor-name">Jane Doe</h5>
              <p className="vendor-email">janedoe@gmail.com</p>
            </div>
            <div className="vendor-joined">
              <p>Joined at: </p>
              <span>Oct 21, 2024</span>
            </div>
          </div>
          <div className="vendor-item">
            <img
              src={profile_avatar}
              alt="Vendor Avatar"
              className="vendor-avatar"
            />
            <div className="vendor-details">
              <h5 className="vendor-name">Jane Doe</h5>
              <p className="vendor-email">janedoe@gmail.com</p>
            </div>
            <div className="vendor-joined">
              <p>Joined at: </p>
              <span>Oct 21, 2024</span>
            </div>
          </div>
          <div className="vendor-item">
            <img
              src={profile_avatar}
              alt="Vendor Avatar"
              className="vendor-avatar"
            />
            <div className="vendor-details">
              <h5 className="vendor-name">Jane Doe</h5>
              <p className="vendor-email">janedoe@gmail.com</p>
            </div>
            <div className="vendor-joined">
              <p>Joined at: </p>
              <span>Oct 21, 2024</span>
            </div>
          </div>

          <div className="pagination">
            <span className="dot active"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </div>
        </div>
      </div>
      <EmptyRecntlyJoined />
    </div>
  );
};

export default RecentlyJoinedVendors;
