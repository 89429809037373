import React, { useState, useEffect } from "react";
import Icons from "../../utils/icons";
import { Button } from "antd";

const PaginationComponent = ({
    totalPages,
    itemsPerPage,
    currentPage,
    onPageChange,
    onPageSizeChange,
    isExport,
    onExcelClick
}) => {

    const [selectedPageSize, setSelectedPageSize] = useState(itemsPerPage);

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5; 

        if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            if (currentPage <= 3) {
                for (let i = 1; i <= 3; i++) pageNumbers.push(i);
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            } else if (currentPage >= totalPages - 2) {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = totalPages - 2; i <= totalPages; i++) pageNumbers.push(i);
            } else {
                pageNumbers.push(1);
                pageNumbers.push("...");
                for (let i = currentPage - 1; i <= currentPage + 1; i++) pageNumbers.push(i);
                pageNumbers.push("...");
                pageNumbers.push(totalPages);
            }
        }

        return pageNumbers;
    };

    const handlePageSizeChange = (e) => {
        const newPageSize = parseInt(e.target.value, 10);
        setSelectedPageSize(newPageSize);
        onPageSizeChange(newPageSize);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) onPageChange(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) onPageChange(currentPage + 1);
    };

    return (
        <div className="section-pagination">
            <div className="pagination-container">
                <div className="page-size-selector">
                    <label htmlFor="page-size">Row Count </label>
                    <select
                        id="page-size"
                        value={selectedPageSize}
                        onChange={handlePageSizeChange}
                        className="page-size-dropdown"
                    >
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={6}>6</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                </div>

                <ul className="pagination">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                        <button className="prev-link" onClick={handlePrevPage}>
                            <img src={Icons.caret_left}></img>
                        </button>
                    </li>

                    {getPageNumbers().map((page, index) => (
                        <li
                        key={index}
                        className={`page-item ${page === currentPage ? "active" : ""} ${page === "..." ? "dots" : ""}`}
                        >
                        <button
                            className="page-link"
                            onClick={() => page !== "..." && onPageChange(page)}
                            disabled={page === "..." || page === currentPage}
                        >
                            {page === "..." ? "\u22EF" : page} 
                        </button>
                    </li>                
                    ))}

                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                        <button className="next-link" onClick={handleNextPage}>
                            <img src={Icons.caret_next}></img>
                        </button>
                    </li>
                </ul>
                {isExport ? (
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => onExcelClick()}
                        className="excel-button"
                    >
                        <img src={Icons.exls}></img>
                        <span>Download Excel</span>
                    </Button>
                ) : (
                    ""
                )}
            </div>

        </div>
    );
};

export default PaginationComponent;